import classNames from 'classnames/bind';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

import Button from '../../components/Button/Button';
import styles from './RegisterPage.module.scss';
import WrapComponentEmpty from '../../components/WrapComponentEmpty/WrapComponentEmpty';
import Topic from '../../components/Topic/Topic';
import { backendUrl } from '../../config';

let cx = classNames.bind(styles);

function RegisterPage() {
  const navigate = useNavigate()

  const [errorLogin, setErrorLogin] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorPw, setErrorPw] = useState(false)
  const [errorLengthPw, setErrorLengthPw] = useState(false)
  const [errorRePwIncorrect, setErrorRePwIncorrect] = useState(false)

  const [valueEmail, setValueEmail] = useState('')
  const [valuePw, setValuePw] = useState('')
  const [valueRePw, setValueRePw] = useState('')

  // type password
  const [typePassword, setTypePassword] = useState('password')
  // type rePassword
  const [typeRePassword, setTypeRePassword] = useState('password')

  const handleSubmit = (e) => {
    e.preventDefault()

    // lỗi trường email chưa nhập
    if (valueEmail === '') {
      return setErrorEmail(true)
    } else {
      setErrorEmail(false)
    }

    // lỗi trường mật khẩu chưa nhập
    if (valuePw === '') {
      return setErrorPw(true)
    } else {
      setErrorPw(false)
    }

    // lỗi chưa nhập đủ ít nhất 3 kí tự
    if (valuePw.length < 3) {
      return setErrorLengthPw(true)
    } else {
      setErrorLengthPw(false)
    }

    // lỗi nhập lại mật khẩu không đúng
    if (valuePw !== valueRePw) {
      return setErrorRePwIncorrect(true)
    } else {
      setErrorRePwIncorrect(false)
    }

    async function getUser() {
      try {
        const response = await axios.post(`${backendUrl}/users/add-account`, {
          email: valueEmail,
          password: valuePw
        })

        if (response.data === 'Email already exists!') {
          setErrorLogin(true)
        } else if (response.data === 'Create account sucessfully!') {
          setErrorLogin(false)
          alert('Đăng ký tài khoản thành công')
          navigate('/log-in')
        }

      } catch (error) {
        console.error(error);
      }
    }
    getUser()
  }

  return (
    <div className={cx('register')}>
      {/* Topic */}

      {/* form-register */}
      <form onSubmit={handleSubmit} className={cx('form-register')}>
        <div className={cx('wrap')}>
          <Topic content='Đăng ký' />

          <div className={cx('data')}>
            <label htmlFor='email'>Email</label>
            <input
              value={valueEmail}
              onChange={e => {
                setValueEmail(e.target.value)
              }}
              type='email'
              id='email'
              placeholder='Nhập email của bạn'
            />
            {errorEmail && <p>Bạn chưa nhập email!</p>}
            {errorLogin && <p>Email đã tồn tại!</p>}
          </div>

          <div className={cx('data')}>
            <label htmlFor='password'>Mật khẩu</label>
            <input
              value={valuePw}
              onChange={e => {
                setValuePw(e.target.value)
              }}
              type={typePassword}
              name="password"
              spellCheck={false}
              id='password'
              placeholder='Nhập mật khẩu của bạn'
            />
            {errorPw && <p> Bạn chưa nhập mật khẩu!</p>}
            {errorLengthPw && <p> Mật khẩu phải có ít nhất 3 kí tự!</p>}

            {typePassword === 'text' ?
              <FontAwesomeIcon style={{ color: '#fff' }} icon={faEye} onClick={() => {
                setTypePassword('password')
              }} />
              :
              <FontAwesomeIcon style={{ color: '#fff' }} icon={faEyeSlash} onClick={() => {
                setTypePassword('text')
              }} />

            }
          </div>
          <div className={cx('data')}>
            <label htmlFor='rePassword'>Nhập lại mật khẩu</label>
            <input
              value={valueRePw}
              onChange={e => {
                setValueRePw(e.target.value)
              }}
              type={typeRePassword}
              name="rePassword"
              spellCheck={false}
              id='rePassword'
              placeholder='Nhập lại mật khẩu'
            />
            {errorRePwIncorrect && <p>Mật khẩu nhập lại không khớp!</p>}

            {typeRePassword === 'text' ?
              <FontAwesomeIcon icon={faEye} onClick={() => {
                setTypeRePassword('password')
              }} />
              :
              <FontAwesomeIcon icon={faEyeSlash} onClick={() => {
                setTypeRePassword('text')
              }} />

            }
          </div>

          {/* button register */}
          <WrapComponentEmpty>
            <Button content='Đăng ký' style={{ 'backgroundColor': '#37869e' }} />
          </WrapComponentEmpty>

          {/* log-in */}
          <WrapComponentEmpty>
            <div className={cx('log-in')}>
              <span>Bạn đã có tài khoản ?</span>
              <Link to='/log-in'>Đăng nhập</Link>
            </div>
          </WrapComponentEmpty>
        </div>
      </form>
    </div >
  );
}

export default RegisterPage;
