import classNames from 'classnames/bind';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft, faClockRotateLeft, faComment, faSquarePlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './UserInforPage.module.scss';
import Profile from './UserInforPageItems/Profile';
import CommentPage from './UserInforPageItems/CommentPage';
import HistoryPage from './UserInforPageItems/HistoryPage';
import FollowedPage from './UserInforPageItems/FollowedPage';
import UpdatePassword from './UserInforPageItems/UpdatePassword';
import Lever from '../../components/Lever/Lever';
import { setRenderPageUser } from '../../reducer/renderPageUserSlice';
import Experience from '../../components/Experience/Experience';
import PostTime from '../../components/PostTime/PostTime';

let cx = classNames.bind(styles);

function UserInforPage() {

  // use navigate
  const naviagte = useNavigate()
  const dispatch = useDispatch()

  // infor film
  const inforUser = useSelector((state) => {
    return state.user.value
  })

  const renderPageUser = useSelector((state) => {
    return state.renderPageUser.value
  })

  useEffect(() => {
    if (!inforUser) {
      return naviagte('/')
    }
  }, [])

  return (
    <div className={cx('wrap-user-infor')}>
      <div className={cx('side-bar')}>
        <div className={cx('wrap-infor-user-and-avatar')}>
          <div className={cx('wrap-avatar')}>
            <div className={cx('avatar')}>
              {
                inforUser
                &&
                <>
                  {
                    inforUser.avatar === null || !inforUser.avatar.includes('googleusercontent')
                      ?
                      <img alt='avatar' src={`/avatar-vo-danh-37.jpg`} />
                      :
                      <img alt='avatar' src={inforUser.avatar} />
                  }
                </>
              }
            </div>

            {inforUser && <span className={`hide-on-m ${cx('level')}`}>{Lever(inforUser.level)}</span>}

            <a
              href="https://myaccount.google.com/profile"
              className={cx('btn-change-avatar')}
            >
              Thay Avatar
            </a>
          </div>

          <div className={`hide-on-t hide-on-ps-pl ${cx('wrap-infor-user')}`}>
            <div className={cx('infor-user-item')}>
              <span className={cx('title')}>Nickname: </span>
              <h3 className={cx('content')}>{inforUser.nick_name}</h3>
            </div>

            <div className={cx('infor-user-item')}>
              <span className={cx('title')}>Level: </span>
              <h3 className={cx('content')}>{Lever(inforUser.level)}</h3>
            </div>

            <div className={cx('infor-user-item')}>
              <span className={cx('title')}>Kinh nghiệm: </span>
              <h3 className={cx('content')}>{Experience(inforUser.level)}</h3>
            </div>

            <div className={cx('infor-user-item')}>
              <span className={cx('title')}>Email: </span>
              <h3 className={cx('content')}>{inforUser.email}</h3>
            </div>

            <div className={cx('infor-user-item')}>
              <span className={cx('title')}>Ngày tham gia: </span>
              <h3 className={cx('content')}>{PostTime(inforUser.registration_date)}</h3>
            </div>
          </div>
        </div>

        <div className={cx('wrap-category')}>

          <div
            className={`hide-on-m ${cx('category-item')}`}
            style={renderPageUser === 'profile' ? { 'backgroundColor': '#55918c' } : {}}
            onClick={() => {
              dispatch(setRenderPageUser('profile'))
            }}
          >
            <FontAwesomeIcon icon={faUser} />
            <span className={cx('title')}>Hồ sơ</span>
          </div>

          <div
            className={cx('category-item')}
            style={renderPageUser === 'showFollowedPage' ? { 'backgroundColor': '#55918c' } : {}}
            onClick={() => {
              dispatch(setRenderPageUser('showFollowedPage'))
            }}
          >
            <FontAwesomeIcon icon={faSquarePlus} />
            <span className={cx('title')}>Theo dõi</span>
          </div>

          <div
            className={cx('category-item')}
            style={renderPageUser === 'history' ? { 'backgroundColor': '#55918c' } : {}}
            onClick={() => {
              dispatch(setRenderPageUser('history'))
            }}
          >
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <span className={cx('title')}>Lịch sử</span>
          </div>

          <div
            className={cx('category-item')}
            style={renderPageUser === 'comment' ? { 'backgroundColor': '#55918c' } : {}}
            onClick={() => {
              dispatch(setRenderPageUser('comment'))
            }}
          >
            <FontAwesomeIcon icon={faComment} />
            <span className={cx('title')}>Bình luận</span>
          </div>

          <div
            className={cx('category-item')}
            style={renderPageUser === 'updatePassword' ? { 'backgroundColor': '#55918c' } : {}}
            onClick={() => {
              dispatch(setRenderPageUser('updatePassword'))
            }}
          >
            <FontAwesomeIcon icon={faArrowRightArrowLeft} />
            <span className={cx('title')}>Đổi mật khẩu</span>
          </div>
        </div>
      </div>

      <div className={cx('wrap-content')}>

        {/* logic show component */}
        <div className={`hide-on-m`}>{renderPageUser === 'profile' && <Profile />}</div>
        {renderPageUser === 'showFollowedPage' && <FollowedPage />}
        {renderPageUser === 'history' && <HistoryPage />}
        {renderPageUser === 'comment' && <CommentPage />}
        {renderPageUser === 'updatePassword' && <UpdatePassword />}
      </div>
    </div>
  );
}

export default UserInforPage;
