import classNames from "classnames/bind"
import { memo } from 'react'
import axios from "axios"

import styles from '../CommentItems.module.scss'
import Emoji from "../../../Emoji/Emoji"
import { backendUrl } from "../../../../config"

const cx = classNames.bind(styles)

const AddRepComment = ({
    rerender, setRerender, setShowBlockRepComment, setShowBlockRepRepComment, item, userId, valueRepComment, setValueRepComment
}) => {

    // handle add rep comment
    const handleAddRepComment = (idCommentFilm) => {
        if (valueRepComment.length === 0)
            return alert('Chưa nhập bìnhh luận')

        async function getUser() {
            try {
                const response = await axios.post(`${backendUrl}/users/add-rep-comment`, {
                    userId: userId,
                    content: valueRepComment,
                    idCommentFilm: idCommentFilm,
                });

                if (response.data === 'Add rep comment sucessfully!') {
                    setShowBlockRepComment(false)
                    setShowBlockRepRepComment(false)
                    setRerender(!rerender)
                    setValueRepComment('')
                }

            } catch (error) {
                console.error(error);
            }
        }
        getUser()

    }

    return (
        <div className={cx('add-rep-comment')}>
            <textarea
                value={valueRepComment}
                onChange={e => {
                    setValueRepComment(e.target.value)
                }}
                spellCheck={false} placeholder='Nhập bình luận của bạn tại đây'
            />
            
            <div className={cx('wrap-btn-send')}>
                <div className={cx('wrap-emoji')}>
                    <Emoji setInputValue={setValueRepComment} />
                </div>

                <div className={cx('wrap-send')}>
                    <button className={cx('cancel')} onClick={() => {
                        setShowBlockRepComment(false)
                        setShowBlockRepRepComment(false)
                    }}>
                        Hủy
                    </button>

                    <button className={cx('send')} onClick={() => {
                        handleAddRepComment(item.id)
                    }}>
                        Gửi
                    </button>
                </div>
            </div>
        </div>
    )
}

export default memo(AddRepComment)