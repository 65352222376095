import classNames from 'classnames/bind';
import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './UserInforPageItems.module.scss';
import WrapComponentEmpty from '../../../components/WrapComponentEmpty/WrapComponentEmpty';
import { backendUrl } from '../../../config';

let cx = classNames.bind(styles);

function ChangePassword() {
    const [valueNewPw, setValueNewPw] = useState('')
    const [valueReNewPw, setValueReNewPw] = useState('')

    const [errorNewPassword, setErrorNewPassword] = useState(false)
    const [errorLengthNewPassword, setErrorLengthNewPassword] = useState(false)
    const [errorReNewPwIncorrect, setErrorReNewPwIncorrect] = useState(false)

    // use selector
    const email = useSelector((state) => {
        return state?.user?.value?.email
    })

    const handleSubmit = (e) => {
        e.preventDefault()

        // lỗi trường mật khẩu mới chưa nhập
        if (valueNewPw === '') {
            return setErrorNewPassword(true)
        } else {
            setErrorNewPassword(false)
        }

        // lỗi chưa nhập đủ ít nhất 3 kí tự
        if (valueNewPw.length < 3) {
            return setErrorLengthNewPassword(true)
        } else {
            setErrorLengthNewPassword(false)
        }

        // lỗi nhập lại mật khẩu không đúng
        if (valueNewPw !== valueReNewPw) {
            return setErrorReNewPwIncorrect(true)
        } else {
            setErrorReNewPwIncorrect(false)
        }

        async function getUser() {
            try {
                const response = await axios.put(`${backendUrl}/users/change-password`, {
                    email: email,
                    newPassword: valueNewPw
                })

                if (response.data === 'Update password succesfully!') {
                    setValueNewPw('')
                    setValueReNewPw('')
                    alert('Đổi mật khẩu thành công')
                }
            } catch (error) {
                console.error(error);
            }
        }
        getUser()
    }

    return (
        <div className={cx('update-password')}>
            <WrapComponentEmpty>
                <form onSubmit={handleSubmit} className={cx('form-login')}>
                    <div className={cx('wrap-infor')}>
                        <label htmlFor='new-password'>Mật khẩu mới</label>
                        <input
                            value={valueNewPw}
                            onChange={e => {
                                setValueNewPw(e.target.value)
                            }}
                            type='password'
                            id='new-password'
                            placeholder='Nhập mật khẩu mới của bạn'
                        />
                        {errorNewPassword && <p>Vui lòng nhập trường này!</p>}
                        {errorLengthNewPassword && <p>Phải nhập ít nhất 3 kí tự!</p>}
                    </div>

                    <div className={cx('wrap-infor')}>
                        <label htmlFor='re-password'>Nhập lại mật khẩu mới</label>
                        <input
                            value={valueReNewPw}
                            onChange={e => {
                                setValueReNewPw(e.target.value)
                            }}
                            type='password'
                            id='re-password'
                            placeholder='Nhập Nhập lại mật khẩu của bạn'
                        />
                        {errorReNewPwIncorrect && <span>Mật khẩu nhập lại không khớp!</span>}
                    </div>

                    <div className={cx('wrap-btn-change-password')}>
                        <input type="submit" value='Đổi mật khẩu' />
                    </div>
                </form>
            </WrapComponentEmpty>
        </div>
    );
}

export default ChangePassword;
