import {  memo, useState, useEffect } from 'react'
import classNames from 'classnames/bind';
import { Link, } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

import styles from './Header.module.scss'
import MenuUser from '../../../components/MenuUser/MenuUser';
import Search from './HeaderItems/Search';
import NavbarMobile from './HeaderItems/NavbarMobile';
import { updateListDetailFilm, updateListTopDay, updateListTopMonth, updateListTopWeek } from '../../../reducer/filterSlice';
import { useDispatch } from 'react-redux';
import { backendUrl } from '../../../config';

let cx = classNames.bind(styles);

function Header() {
    const [showSearch, setShowSearch] = useState(false)

    const dispatch = useDispatch()

    // use selector
    const inforUser = useSelector((state) => {
        return state.user.value
    })

    useEffect(() => {
        async function getUser() {
          try {
            const query1 = axios.get(`${backendUrl}/films/list-detail-film`)
            const month = axios.get(`${backendUrl}/films/top-month-week-day?top=month`)
            const week = axios.get(`${backendUrl}/films/top-month-week-day?top=week`)
            const day = axios.get(`${backendUrl}/films/top-month-week-day?top=day`)
    
            const [response1, responseTopMonth, responseTopWeek, responseTopDay] =
              await Promise.all([query1, month, week, day])

              console.log('response1', response1);
    
            dispatch(updateListDetailFilm(response1.data))
            dispatch(updateListTopMonth(responseTopMonth.data))
            dispatch(updateListTopWeek(responseTopWeek.data))
            dispatch(updateListTopDay(responseTopDay.data))
          } catch (error) {
            console.error(error);
          }
        }
        getUser()
      }, [])
    

    return (
        <header className={cx('wrap-header')}>
            <div className='grid'>
                <div className='wide'>
                    <div className={cx('header')}>
                        <Link to="/" className={cx('logo')}>HoatHinh3Q</Link>

                        {/* search */}
                        {<Search show={showSearch} />}

                        <NavbarMobile />

                        <div className='hide-on-m'>{
                            inforUser ?
                                <MenuUser />
                                :
                                <div className={cx('header-wrap-login')}>
                                    <Link to={`/log-in`}>Đăng nhập</Link>
                                    <Link to={`/register`}>Đăng ký</Link>
                                </div>
                        }
                        </div>
                    </div >
                </div>
            </div>
        </header >
    )
}

export default memo(Header)