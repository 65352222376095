import { useState, useEffect, memo } from 'react'
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import styles from './HeaderItems.module.scss'
import { Link } from 'react-router-dom';
import { backendUrl } from '../../../../config';

let cx = classNames.bind(styles);

function Search({ show }) {

    // value search
    const [searchValue, setSearchValue] = useState('')

    const [showBlockSearch, setShowBlockSearch] = useState(false)

    // data
    const [data, setData] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        if (searchValue !== '') {
            const id = setTimeout(() => {

                async function getUser() {
                    try {
                        const response = await axios.get(`${backendUrl}/films/search-film`, {
                            params: {
                                search: searchValue
                            }
                        });

                        setData(response.data)
                    } catch (error) {
                        console.error(error);
                    }
                }
                getUser()
            }, 1000)

            return () => {
                clearTimeout(id)
            }
        }
    }, [searchValue])

    // handle search film reducer
    const handleClickSearch = () => {
        if (searchValue.trim() === '') {
            alert('Bạn chưa nhập từ khóa tìm kiếm')
        } else {
            navigate(`/search-film?search=${searchValue}`)
            setShowBlockSearch(false)
        }
    }

    return (
        <div className={`${show === false && 'hide-on-m-t'} ${cx('search')}`}>
            <div className={cx('before')}>
                <input
                    value={searchValue}
                    onChange={(e) => {
                        setSearchValue(e.target.value)
                        setShowBlockSearch(true)
                    }}

                    placeholder='Nhập từ khóa...'
                    spellCheck={false}

                    onClick={() => {
                        setShowBlockSearch(true)
                    }}
                />
                <button
                    onClick={handleClickSearch}
                >
                    <FontAwesomeIcon className={cx('search-icon')} icon={faMagnifyingGlass} />
                </button>

            </div>

            {
                data?.length !== 0 && showBlockSearch && (
                    <ul className={cx('after')}>
                        {data?.map((item, index) => (
                            <li key={index}>
                                <Link onClick={() => {
                                    setShowBlockSearch(false)
                                }}
                                    to={`/movie-information/${item.slug}`}
                                    className={cx('conver-page-search')}
                                >
                                    <div className={cx('wrap-avartar')}>
                                        <img alt='avata' src={item.image} />
                                    </div>

                                    <div className={cx('details')}>
                                        <h3 className={cx('details-name')}>{item.name}</h3>
                                        <span className={cx('details-episode')}>
                                            {item.current_episode || '??'}/{item.number_episodes || '??'}
                                        </span>

                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                )
            }
        </div>

    )
}

export default memo(Search)