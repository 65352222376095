import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import axios from 'axios';

import styles from './UserInforPageItems.module.scss'
import Topic from '../../../components/Topic/Topic';
import { useSelector } from 'react-redux';
import Paginate from '../../../components/Paginate/Paginate';
import { backendUrl } from '../../../config';

let cx = classNames.bind(styles);


function HistoryPage() {
    const [data, setData] = useState()

    // use selector
    const inforUsers = useSelector(state => (state.user.value))

    useEffect(() => {

        const getData = async function () {
            try {
                const response1 = await axios.get(`${backendUrl}/users/history-film`, {
                    params: {
                        userId: inforUsers.id
                    }
                });

                setData(response1.data)

                if (response1.data.length >= 100) {
                    await axios.delete(`${backendUrl}/users/remote-history`, {
                        data: {
                            userId: inforUsers.id
                        }
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
        getData()
    }, [])

    return (
        <div className={cx('history')}>

            {/* stylesMovie */}
            <Topic content='Lịch sử xem' />
            {
                inforUsers && data && <Paginate itemsPerPage={30} topic={false} topMovie={false} items={data} />
            }
        </div>
    );
}

export default HistoryPage;
