import { useState, useEffect, memo } from 'react'
import classNames from 'classnames/bind';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faBars, faChevronDown, faHouse, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';

import styles from './HeaderItems.module.scss'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MenuUser from '../../../../components/MenuUser/MenuUser';
import { updateDataFilter, updateGenres, updateStatus, updateTopMovie, updateYears } from '../../../../reducer/filterSlice';
import { FilterGenres, FilterStatus, FilterTopMovie, FilterYears } from '../../../../components/FilterMovie/FilterMovie';
import { backendUrl } from '../../../../config';

let cx = classNames.bind(styles);

function NavbarMobile({ show }) {

    // use navigate
    const navigate = useNavigate()

    // use dispatch
    const dispatch = useDispatch()

    // use selector
    const inforUser = useSelector((state) => {
        return state.user.value
    })

    const location = useLocation()
    const { listDetailFilm, listTopMonth, listTopWeek, listTopDay } = useSelector((state) => state.filter)

    const [showFormSearchMobile, setShowFormSearchMobile] = useState(false)

    // value search
    const [searchValue, setSearchValue] = useState('')

    const [showSelectSearchMobile, setShowSelectSearchMobile] = useState(false)

    // data
    const [genresList, setGenresList] = useState()
    const [yearList, setYearList] = useState()

    const [showGenresMobile, setShowGenresMobile] = useState(false)
    const [showStatusMobile, setshowStatusMobile] = useState(false)
    const [showYearsMobile, setShowYearsMobile] = useState(false)
    const [showTopMoviesMobile, setShowTopMoviesMobile] = useState(false)

    // data
    const [data, setData] = useState(null)

    useEffect(() => {
        async function getUser() {
            try {
                const query1 = axios.get(`${backendUrl}/films/list-genre`)
                const query2 = axios.get(`${backendUrl}/films/list-year`)

                const [response1, response2] = await Promise.all([query1, query2])

                setGenresList(response1?.data)
                setYearList(response2?.data)
            } catch (error) {
                console.error(error);
            }
        }
        getUser()
    }, [])

    useEffect(() => {
        if (searchValue !== '') {
            const id = setTimeout(() => {

                async function getUser() {
                    try {
                        const response = await axios.get(`${backendUrl}/films/search-film`, {
                            params: {
                                search: searchValue
                            }
                        });

                        setData(response.data)
                    } catch (error) {
                        console.error(error);
                    }
                }
                getUser()
            }, 1000)

            return () => {
                clearTimeout(id)
            }
        } else {
            setData(null)
        }
    }, [searchValue])

    const handleClickSearch = () => {
        if (searchValue.trim() === '') {
            alert('Bạn chưa nhập từ khóa tìm kiếm')
        } else {
            navigate(`/search-film?search=${searchValue}`)
            setShowSelectSearchMobile(false)
        }
    }

    const handleFilterGenre = (genre) => {
        setShowSelectSearchMobile(false)
        const dataFilter = FilterGenres(listDetailFilm, [genre]);
        dispatch(updateDataFilter(dataFilter));
        // để hủy tất cả các lựa chọn genre
        dispatch(updateGenres(''));
        // để chọn chỉ một genre
        dispatch(updateGenres(genre));
        dispatch(updateYears(''));
        dispatch(updateStatus(''));
        dispatch(updateTopMovie(''));
        navigate('/filter-movie');
    }

    const handleFilterStatus = (status) => {
        setShowSelectSearchMobile(false)
        const dataFilter = FilterStatus(listDetailFilm, status);
        dispatch(updateDataFilter(dataFilter));
        dispatch(updateGenres(''));
        dispatch(updateYears(''));
        dispatch(updateStatus(status));
        dispatch(updateTopMovie(''));
        navigate('/filter-movie');
    }

    const handleFilterYear = (year) => {
        setShowSelectSearchMobile(false)
        const dataFilter = FilterYears(listDetailFilm, [year]);
        dispatch(updateDataFilter(dataFilter));
        dispatch(updateGenres(''));
        // để hủy tất cả các lựa chọn year
        dispatch(updateYears(''));
        // để chọn chỉ một year
        dispatch(updateYears(year));
        dispatch(updateStatus(''));
        dispatch(updateTopMovie(''));
        navigate('/filter-movie');
    }

    const handleFilterTopMovie = (topMovie) => {
        setShowSelectSearchMobile(false)
        const dataFilter = FilterTopMovie(listDetailFilm, listTopMonth, listTopWeek, listTopDay, topMovie);
        dispatch(updateDataFilter(dataFilter));
        dispatch(updateGenres(''));
        dispatch(updateYears(''));
        dispatch(updateStatus(''));
        dispatch(updateTopMovie(topMovie));
        navigate('/filter-movie');
    }

    return (
        <div className={cx('wrap-navbar-movie')}>
            <div className={cx('search-mobile', 'hide-on-t', 'hide-on-ps-pl')}>
                <div className={cx('wrap-icon')}>
                    {
                        inforUser ?
                            <MenuUser />
                            :
                            <Link to={'/log-in'} className={cx('wrap-icon-login')}>
                                <FontAwesomeIcon
                                    icon={faArrowRightFromBracket}
                                />
                            </Link>
                    }


                    {showSelectSearchMobile ?
                        <FontAwesomeIcon onClick={() => {
                            setShowSelectSearchMobile(false)
                        }} className={cx('menu-icon')} icon={faXmark} />
                        :
                        <FontAwesomeIcon onClick={() => {
                            setShowSelectSearchMobile(true)
                        }} className={cx('menu-icon')} icon={faBars} />
                    }
                </div>

                {showSelectSearchMobile && <div className={cx('wrap-select-search')}>
                    <div className={cx('wrap-input-search-mobile')}>
                        <div className={cx('before')}>
                            <input
                                value={searchValue}
                                onChange={(e) => {
                                    setSearchValue(e.target.value)
                                }}
                                onClick={() => {
                                    setShowFormSearchMobile(true)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleClickSearch()
                                    }
                                }}
                                placeholder='Nhập từ khóa'
                            />

                            <div className={cx('wrap-icon')}
                                onClick={handleClickSearch}
                            >
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </div>
                        </div>

                        {
                            data && showFormSearchMobile && (
                                <ul className={cx('after')}>
                                    {data?.map((item, index) => (
                                        <li key={index} onClick={() => {
                                            setShowFormSearchMobile(false)
                                            setShowSelectSearchMobile(false)
                                        }}>
                                            <Link 
                                                to={`/movie-information/${item.slug}`}
                                                className={cx('conver-page-search')}
                                            >
                                                <div className={cx('wrap-avartar')}>
                                                    <img alt='avata' src={item.image} />
                                                </div>

                                                <div className={cx('details')}>
                                                    <h3 className={cx('details-name')}>{item.name}</h3>
                                                    <span className={cx('details-episode')}>
                                                        {item.current_episode || '??'}/{item.number_episodes || '??'}
                                                    </span>

                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )
                        }
                    </div>

                    <Link
                        to='/'
                        className={cx('wrap-home')}
                        style={
                            location.pathname === '/' ?
                                { 'color': '#ffae3c' } : { 'color': '#eee' }
                        }
                    >
                        <FontAwesomeIcon icon={faHouse} />
                        <span>Trang chủ</span>
                    </Link>

                    <div className={cx('wrap-topic')}>
                        <div
                            style={showGenresMobile ?
                                { 'color': '#ffae3c' } : { 'color': '#eee' }
                            }
                            className={cx('wrap-name')}
                            onClick={() => {
                                setShowGenresMobile(!showGenresMobile)
                            }}>
                            <span>Thể loại</span>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                        {
                            showGenresMobile
                            && <ul className={`row ${cx('topic-list')}`}>
                               {
                                    genresList?.map(genre => (
                                        <li
                                            key={genre.genre}
                                            onClick={() => { handleFilterGenre(genre.genre) }}
                                            className={`l-6 ${cx('topic-item')}`}
                                        >
                                            <Link className={cx('content')}>{genre.genre}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        }
                    </div>

                    <div className={cx('wrap-topic')}>
                        <div
                            style={showStatusMobile ?
                                { 'color': '#ffae3c' } : { 'color': '#eee' }
                            }
                            className={cx('wrap-name')}
                            onClick={() => {
                                setshowStatusMobile(!showStatusMobile)
                            }}>
                            <span>Trạng thái</span>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                        {
                            showStatusMobile &&
                            <ul className={`row ${cx('topic-list')}`}>
                                <li
                                    onClick={() => { handleFilterStatus('Đã hoàn thành') }}
                                    className={cx('topic-item')}
                                >
                                    <div className={cx('content')}>Đã hoàn thành</div>
                                </li>
                                <li
                                    onClick={() => { handleFilterStatus('Chưa hoàn thành') }}
                                    className={cx('topic-item')}
                                >
                                    <div className={cx('content')}>Chưa hoàn thành</div>
                                </li>
                            </ul>
                        }
                    </div>

                    <div className={cx('wrap-topic')}>
                        <div
                            className={cx('wrap-name')}
                            style={showYearsMobile ?
                                { 'color': '#ffae3c' } : { 'color': '#eee' }
                            }
                            onClick={() => {
                                setShowYearsMobile(!showYearsMobile)
                            }}>
                            <span>Năm</span>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                        {
                            showYearsMobile &&
                            <ul className={`row ${cx('topic-list')}`}>
                                {
                                    yearList?.map(year => (
                                        <li
                                            key={year.year}
                                            onClick={() => { handleFilterYear(year.year) }}
                                            className={`l-6 ${cx('topic-item')}`}
                                        >
                                            <Link className={cx('content')}>{year.year || '??'}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        }
                    </div>

                    <div className={cx('wrap-topic')}>
                        <div
                            style={showTopMoviesMobile ?
                                { 'color': '#ffae3c' } : { 'color': '#eee' }
                            }
                            className={cx('wrap-name')}
                            onClick={() => {
                                setShowTopMoviesMobile(!showTopMoviesMobile)
                            }}
                        >
                            <span>Top phim</span>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                        {
                            showTopMoviesMobile
                            &&
                            <ul className={`row ${cx('topic-list')}`}>
                                <li
                                    onClick={() => { handleFilterTopMovie('Top month') }}
                                    className={`l-6 ${cx('topic-item')}`}
                                >
                                    <Link className={cx('content')}>Top tháng</Link>
                                </li>
                                <li
                                    onClick={() => { handleFilterTopMovie('Top week') }}
                                    className={`l-6 ${cx('topic-item')}`}
                                >
                                    <Link className={cx('content')}>Top tuần</Link>
                                </li>
                                <li
                                    onClick={() => { handleFilterTopMovie('Top day') }}
                                    className={`l-6 ${cx('topic-item')}`}
                                >
                                    <Link className={cx('content')}>Top ngày</Link>
                                </li>
                                <li
                                    onClick={() => { handleFilterTopMovie('Top evaluate') }}
                                    className={`l-6 ${cx('topic-item')}`}
                                >
                                    <Link className={cx('content')}>Đánh giá</Link>
                                </li>
                                <li
                                    onClick={() => { handleFilterTopMovie('Top following') }}
                                    className={`l-6 ${cx('topic-item')}`}
                                >
                                    <Link className={cx('content')}>Theo dõi</Link>
                                </li>
                            </ul>
                        }
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default memo(NavbarMobile)