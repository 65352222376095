import styles from './LayoutDefault.module.scss'
import classNames from 'classnames/bind';

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import TopMovie from '../../components/TopMovie/TopMovie';
import Navbar from '../components/Navbar/Navbar';
import { useSelector } from 'react-redux';

let cx = classNames.bind(styles);

function LayoutDefault({ children, topMovie = true }) {

    const { listDetailFilm } = useSelector((state) => state.filter)

    return (
        <div className={cx('layout-default')}>
            <Header />
            {listDetailFilm.length > 0 && <Navbar />}

            {listDetailFilm.length > 0 && <div className={cx('wrap-body')}>
                <div className='grid'>
                    <div className='wide'>
                        {
                            topMovie ?
                                <div className={`row ${cx('row-section')}`}>
                                    <div className={`l-8-5 t-12 m-12 ${cx('column1')}`}>
                                        {children}
                                    </div>

                                    <div className={`l-3-5 hide-on-m-t ${cx('column2')}`}>
                                        <TopMovie />
                                    </div>

                                </div>
                                :
                                <div className={cx('body')}>
                                    {children}
                                </div>
                        }
                    </div>
                </div>
            </div>}

            {/* footer */}
            {listDetailFilm.length > 0 && <Footer />}
        </div>
    )
}

export default LayoutDefault