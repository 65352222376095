import axios from "axios"
import { useEffect, useState } from "react"
import { backendUrl } from "../../config"


function App() {

    const [data, setData] = useState()

    useEffect(() => { 
      async function getUser() {
        try { 
          const response = await axios.get(`${backendUrl}/films/list-film`)

          console.log('data', response?.data);

          setData(response?.data)
        } catch (error) {
          console.error(error);
          console.log('xin chao');
        }
      }
      getUser()
    }, [])
    
    return (
        <div>
            <h2>React Google Login</h2>
            {
                data?.map(item => (
                    <ul>
                        <li>
                            <h3>{item.name}</h3>
                            <p>{item.description}</p>
                        </li>
                    </ul>
                ))
            }
        </div>
    )
}
export default App;