import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';
import classNames from 'classnames/bind';
import Random from '../../../components/Random/Random';
import { memo } from 'react';

let cx = classNames.bind(styles);

function Footer() {

    return (
        <footer className={cx('footer')}>
            <div className="grid">
                <div className="wide">
                    <div className={cx('wrap-footer')}>
                        <Link to="/" className={cx('logo')}>HoatHinh3Q</Link>
                        <Random />
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default memo(Footer);
