import styles from './TopMovie.module.scss'
import classNames from 'classnames/bind';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown, faEye, faHeart, faStar } from '@fortawesome/free-solid-svg-icons';
import Topic from '../Topic/Topic';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterTopMovie } from '../FilterMovie/FilterMovie';
import { updateYears, updateStatus, updateDataFilter, updateGenres, updateTopMovie, updateListDetailFilm, updateListTopMonth, updateListTopWeek, updateListTopDay } from '../../reducer/filterSlice';
import axios from 'axios';
import { backendUrl } from '../../config';

let cx = classNames.bind(styles);

function TopMovie() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { listDetailFilm, listTopMonth } = useSelector((state) => state.filter)

    const [showFilter, setShowFilter] = useState('Top following')

    const dataTopMonth = FilterTopMovie([], listTopMonth, [], [], 'Top month').slice(0, 5)
    const dataTopAll = FilterTopMovie(listDetailFilm, [], [], [], 'Top all').slice(0, 5)
    const dataTopFollowing = FilterTopMovie(listDetailFilm, [], [], [], 'Top following').slice(0, 5)

    const handleViewAll = (showFilter = 'Top following') => {
        const dataFilter = FilterTopMovie(listDetailFilm, [], [], [], showFilter);
        dispatch(updateDataFilter(dataFilter));
        dispatch(updateGenres(''));
        dispatch(updateYears(''));
        dispatch(updateStatus(''));
        dispatch(updateTopMovie(showFilter));
        navigate('/filter-movie');
    }

    return (
        <div className={cx('wrap-top-movie')}>
            < Topic mw0={true} mh0={true} content='Top phim' icon={<FontAwesomeIcon icon={faCrown} />} />

            <div className={cx('top-movie')}>
                <div className={cx('top-movie-before')}>

                    <div
                        onClick={() => { setShowFilter('Top following') }}
                        style={showFilter === 'Top following' ? { 'background': '#d44d4d', 'color': '#fff' } : { 'color': '#eee' }}
                        className={cx('top-movie-title')}
                    >
                        Top theo dõi
                    </div>

                    <div
                        onClick={() => { setShowFilter('Top all') }}
                        style={showFilter === 'Top all' ? { 'background': '#d44d4d', 'color': '#fff' } : { 'color': '#eee' }}
                        className={cx('top-movie-title')}
                    >
                        Top All
                    </div>

                    <div
                        onClick={() => { setShowFilter('Top month') }}
                        style={showFilter === 'Top month' ? { 'background': '#d44d4d', 'color': '#fff' } : { 'color': '#eee' }}
                        className={cx('top-movie-title')}
                    >
                        Top Tháng
                    </div>
                </div>


                {
                    showFilter === 'Top following'
                    &&
                    <ul className={cx('top-movie-after')}>
                        {
                            dataTopFollowing?.map((item, index) => (
                                <li key={index} className={cx('top-movie-item')}>
                                    <Link to={`/movie-information/${item.slug}`}>
                                        <div className={cx('wrap-img')}>
                                            <img alt={item.name} src={item.image} />
                                        </div>
                                        <div className={cx('wrap-infor')}>
                                            <h3>{item.name}</h3>
                                            <div className={cx('wrap-infor-interact')}>

                                                {
                                                    item.views
                                                    &&
                                                    <div className={cx('wrap-icon', 'views')}>
                                                        <FontAwesomeIcon className={cx('icon')} icon={faEye} />
                                                        <span className={cx('parameter')}>{item.views}</span>
                                                    </div>
                                                }

                                                {
                                                    item.medium_point
                                                    &&
                                                    <div className={cx('wrap-icon', 'evaluate')}>
                                                        <FontAwesomeIcon className={cx('icon')} icon={faStar} />
                                                        <span className={cx('parameter')}>{item.medium_point}</span>
                                                    </div>
                                                }

                                                {
                                                    item.followed_film
                                                    &&
                                                    <div className={cx('wrap-icon', 'following')}>
                                                        <FontAwesomeIcon className={cx('icon')} icon={faHeart} />
                                                        <span className={cx('parameter')}>{item.followed_film}</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                }


                {
                    showFilter === 'Top all'
                    &&
                    <ul className={cx('top-movie-after')}>
                        {
                            dataTopAll?.map((item, index) => (
                                <li key={index} className={cx('top-movie-item')}>
                                    <Link to={`/movie-information/${item.slug}`}>
                                        <div className={cx('wrap-img')}>
                                            <img alt={item.name} src={item.image} />
                                        </div>
                                        <div className={cx('wrap-infor')}>
                                            <h3>{item.name}</h3>
                                            <div className={cx('wrap-infor-interact')}>

                                                {
                                                    item.views
                                                    &&
                                                    <div className={cx('wrap-icon', 'views')}>
                                                        <FontAwesomeIcon className={cx('icon')} icon={faEye} />
                                                        <span className={cx('parameter')}>{item.views}</span>
                                                    </div>
                                                }

                                                {
                                                    item.medium_point
                                                    &&
                                                    <div className={cx('wrap-icon', 'evaluate')}>
                                                        <FontAwesomeIcon className={cx('icon')} icon={faStar} />
                                                        <span className={cx('parameter')}>{item.medium_point}</span>
                                                    </div>
                                                }

                                                {
                                                    item.followed_film
                                                    &&
                                                    <div className={cx('wrap-icon', 'following')}>
                                                        <FontAwesomeIcon className={cx('icon')} icon={faHeart} />
                                                        <span className={cx('parameter')}>{item.followed_film}</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                }

                {
                    showFilter === 'Top month'
                    &&
                    <ul className={cx('top-movie-after')}>
                        {
                            dataTopMonth?.map((item, index) => (
                                <li key={index} className={cx('top-movie-item')}>
                                    <Link to={`/movie-information/${item.slug}`}>
                                        <div className={cx('wrap-img')}>
                                            <img alt={item.name} src={item.image} />
                                        </div>
                                        <div className={cx('wrap-infor')}>
                                            <h3>{item.name}</h3>
                                            <div className={cx('wrap-infor-interact')}>

                                                {
                                                    item.views
                                                    &&
                                                    <div className={cx('wrap-icon', 'views')}>
                                                        <FontAwesomeIcon className={cx('icon')} icon={faEye} />
                                                        <span className={cx('parameter')}>{item.views}</span>
                                                    </div>
                                                }

                                                {
                                                    item.medium_point
                                                    &&
                                                    <div className={cx('wrap-icon', 'evaluate')}>
                                                        <FontAwesomeIcon className={cx('icon')} icon={faStar} />
                                                        <span className={cx('parameter')}>{item.medium_point}</span>
                                                    </div>
                                                }

                                                {
                                                    item.followed_film
                                                    &&
                                                    <div className={cx('wrap-icon', 'following')}>
                                                        <FontAwesomeIcon className={cx('icon')} icon={faHeart} />
                                                        <span className={cx('parameter')}>{item.followed_film}</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                }

                <div onClick={() => { handleViewAll(showFilter) }} className={cx('top-movie-all')}>
                    <span >Xem tất cả</span>
                </div>
            </div>

        </div>
    )
}

export default memo(TopMovie)