import classNames from 'classnames/bind';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';

import styles from './InforEpisodeFilmPage.module.scss'
import { backendUrl } from '../../config';

let cx = classNames.bind(styles);

function InforEpisodeFilmPage() {
  const [data, setData] = useState()
  const [reRender, setReRender] = useState(true)

  const [searchParams] = useSearchParams();
  const filmId = searchParams.get('filmId');
  const filmName = searchParams.get('filmName');

  const [searchValue, setSearchValue] = useState('')
  const [showUpdateEpisodeFilm, setShowUpdateEpisodeFilm] = useState('')


  const [server1Value, setServer1Value] = useState('')
  const [server2Value, setServer2Value] = useState('')
  const [server3Value, setServer3Value] = useState('')
  const [sourceLinkValue, setSourceLinkValue] = useState('')
  const [timeValue, setTimeValue] = useState('')

  useEffect(() => {
    async function getUser() {
      try {
        const response = await axios.get(`${backendUrl}/admin/search-episode-film`, {
          params: {
            searchValue: '',
            filmId: filmId
          }
        });
        setData(response.data)

      } catch (error) {
        console.error(error);
      }
    }
    getUser()
  }, [reRender])

  useEffect(() => {
    if (!searchValue) {
      return;
    }

    async function getUser() {
      try {
        const response = await axios.get(`${backendUrl}/admin/search-episode-film`, {
          params: {
            searchValue: searchValue,
            filmId: filmId
          }
        });
        setData(response.data)

      } catch (error) {
        console.error(error);
      }
    }

    const searchTimeout = setTimeout(getUser, 1000)

    return () => {
      clearTimeout(searchTimeout)
    }
  }, [searchValue])

  const handleDeleteEpisodeFilms = (episode_id) => {
    const defind = window.confirm('Bạn chắc chắn muốn xóa!')
    if (!defind) {
        return;
    }

    async function deleteFilm() {
      try {
        const response = await axios.delete(`${backendUrl}/admin/remote-episode-films/${episode_id}`);

        if (response.data === 'Remote film sucessfully!') {
          setReRender(!reRender)
        }

      } catch (error) {
        console.error(error);
      }
    }
    deleteFilm()
  }

  const handleUpdateFilm = (episode_id) => {
    const data = {
      episode_id: episode_id,
      server1Value: server1Value,
      server2Value: server2Value,
      server3Value: server3Value,
      sourceLinkValue: sourceLinkValue,
      timeValue: timeValue,
    }

    async function updateFilm() {
      try {
        const response = await axios.put(`${backendUrl}/admin/update-episode-films`, data);

        if (response.data === 'Update episode films sucessfully!') {
          setShowUpdateEpisodeFilm('')
          setReRender(!reRender)
        }
      } catch (error) {
        console.error(error);
      }
    }
    updateFilm()
  }

  return (
    <div className={cx('list-film')}>

      <div className={cx('wrap-search')}>
        <input value={searchValue} onChange={(e) => {
          setSearchValue(e.target.value)
        }} spellCheck={false} placeholder='Search...' />
      </div>

      <h2 className={cx('name-film')}>
        Phim {data?.[0]?.name}
      </h2>

      <div className={cx('wrap-number-episode-and-add-film')}>
        <div className={cx('wrap-number-episode')}>
          <span className={cx('title')}>Tập phim: </span>

          <span className={cx('wrap-curr-ep-and-num-ep')}>
            {data?.[data.length -1]?.current_episode || '??'}
            /
            {data?.[0]?.number_episodes || '??'}
          </span>
        </div>

        <Link
          to={`/admin/add-episode-film?filmName=${filmName}&id=${filmId}`}
          className={cx('button-film')}
        >
          Thêm tập phim
        </Link>
      </div>


      <div className={cx('wrap-list-film')}>
        {
          data?.map(item => (
            <div key={item.episode_id} className={cx('film-item')}>
              <div className={cx('film-item-before')}>
                <div className={cx('wrap-topic', 'wrap-episode')}>
                  Tập
                  <h3>{item.episode}</h3>
                </div>

                <div className={cx('wrap-topic', 'wrap-server')}>
                  <div className={cx('server-item')}>
                    Server 1
                    {
                      showUpdateEpisodeFilm === item.episode ?
                        <textarea value={server1Value} onChange={(e) => {
                          setServer1Value(e.target.value)
                        }} />
                        :
                        <h3>{item.server1}</h3>
                    }
                  </div>

                  <div className={cx('server-item')}>
                    Server 2
                    {
                      showUpdateEpisodeFilm === item.episode ?
                        <textarea value={server2Value} onChange={(e) => {
                          setServer2Value(e.target.value)
                        }} />
                        :
                        <h3>{item.server2}</h3>
                    }
                  </div>

                  <div className={cx('server-item')}>
                    Server 3
                    {
                      showUpdateEpisodeFilm === item.episode ?
                        <textarea value={server3Value} onChange={(e) => {
                          setServer3Value(e.target.value)
                        }} />
                        :
                        <h3>{item.server3}</h3>
                    }
                  </div>
                </div>

                <div className={cx('wrap-topic', 'wrap-source-link')}>
                  Server 3
                  {
                    showUpdateEpisodeFilm === item.episode ?
                      <textarea value={sourceLinkValue} onChange={(e) => {
                        setSourceLinkValue(e.target.value)
                      }} />
                      :
                      <h3>{item.source_link}</h3>
                  }
                </div>

                <div className={cx('wrap-topic', 'wrap-source-link')}>
                  Time
                  {
                    showUpdateEpisodeFilm === item.episode ?
                      <input value={timeValue} onChange={(e) => {
                        setTimeValue(e.target.value)
                      }} />
                      :
                      <h3>{item.time}</h3>
                  }
                </div>

                <button onClick={() => {
                  handleDeleteEpisodeFilms(item.episode_id)
                }} className={cx('button-film')}
                >
                  Xóa
                </button>

              </div>

              {showUpdateEpisodeFilm === item.episode ?
                <div className={cx('film-item-after')}>
                  <button
                    onClick={() => {
                      handleUpdateFilm(item.episode_id)
                    }}
                  >
                    Sửa
                  </button>

                  <button
                    onClick={() => {
                      setShowUpdateEpisodeFilm('')
                    }}
                  >
                    Huỷ
                  </button>
                </div>
                :
                <div className={cx('film-item-after')}>
                  <button
                    onClick={() => {
                      setShowUpdateEpisodeFilm(item.episode)
                      setServer1Value(item.server1)
                      setServer2Value(item.server2)
                      setServer3Value(item.server3)
                      setSourceLinkValue(item.source_link)
                      setTimeValue(item.time)
                    }}
                  >
                    Sửa
                  </button>
                </div>
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default InforEpisodeFilmPage;
