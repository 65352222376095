// import librarys
import classNames from 'classnames/bind'
import { useEffect, useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

// import source code
import styles from './LoginPageItems.module.scss'
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../../reducer/userSlice';
import { backendUrl } from '../../../config';

const cx = classNames.bind(styles)

const LoginWithGoogle = () => {
    const [user, setUser] = useState([]);
    
    const naviagte = useNavigate()

    // use selector
    const inforUser = useSelector((state) => {
        return state.user.value
    })

    // dispatch
    const dispatch = useDispatch()

    // navigate
    const navigate = useNavigate()

    const loginGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if(inforUser) {
                return naviagte('/')
            }

            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })

                    .then((res) => {
                        async function getUser() {
                            try {
                                const response = await axios.post(`${backendUrl}/users/log-in-google`, {
                                    ...res.data
                                })

                                dispatch(login(response.data))
                                navigate('/')

                            } catch (error) {
                                console.error(error);
                            }
                        }
                        getUser()
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            }
        },
        [user]
    );

    return (
        <div className={cx('login-with-google')} onClick={() => {
            loginGoogle()
        }}>
            <div className={cx('un-loggin')}>
                <span >Đăng nhập với google</span>
                <FontAwesomeIcon icon={faGoogle} />
            </div>
        </div>
    )
}

export default LoginWithGoogle