import React from "react";
import { useState, memo } from 'react'
import classNames from 'classnames/bind';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "./component/settings";

import styles from './HomePageItems.module.scss'
import InforMovie from "../../../components/InforMovie/InforMovie";
import { FilterTopMovie } from "../../../components/FilterMovie/FilterMovie";
import { useSelector } from "react-redux";

let cx = classNames.bind(styles);

const ReactSlick = () => {
    const { listDetailFilm, listTopWeek, listTopDay } = useSelector((state) => state.filter)

    // data
    const [data, setData] = useState(() => {
        // top day
        let result = FilterTopMovie([], [], [], listTopDay, 'Top day').slice(0, 10)
        if (result.length >= 10) {
            return result
        } else {
            // top week
            result = FilterTopMovie([], [], listTopWeek, [], 'Top week').slice(0, 10)
            if (result.length >= 10) {
                return result
            } else {
                // top all
                result = FilterTopMovie(listDetailFilm, [], [], [], 'Top all').slice(0, 10)
                return result
            }
        }
    })

    return (
        <Slider className={cx('slider')} {...settings}>
            {data && data.map((item, index) => (
                <div key={index}>
                    <InforMovie
                        data={item}
                        onClick={() => {
                            window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
                        }}
                    />
                </div>
            ))}
        </Slider>
    )
}

export default memo(ReactSlick);
