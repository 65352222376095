
import classNames from 'classnames/bind';

import styles from './UserInforPageItems.module.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Lever from '../../../components/Lever/Lever'
import PostTime from '../../../components/PostTime/PostTime'
import ReactPaginate from 'react-paginate';
import { backendUrl } from '../../../config';

let cx = classNames.bind(styles);

function CommentPage({ itemsPerPage = 5 }) {

    // selector
    const inforUser = useSelector((state) => {
        return state.user.value
    })

    const [itemOffset, setItemOffset] = useState(0);
    const [items, setItems] = useState([])

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items?.length / itemsPerPage);

    useEffect(() => {
        async function getUser() {
            try {
                const response = await axios.get(`${backendUrl}/users/comment-user`, {
                    params: {
                        userId: inforUser.id
                    }
                });

                setItems(response.data)
            } catch (error) {
                console.error(error);
            }
        }
        getUser()
    }, [])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };

    return (
        <div className={cx('wrap-comment')}>

            {
                currentItems?.map((item, index) => (
                    <div key={index} >
                        <div className={cx('comment')}>
                            <div className={cx('logo')}>
                                <img alt='avatar' src={`https://i.ytimg.com/vi/${item.avatar}/mqdefault.jpg`} />
                            </div>

                            <div className={cx('wrap-content')}>
                                <div className={cx('wrap-user-name')}>
                                    <div className={cx('infor-user-name')}>
                                        <div className={cx('before')}>
                                            <span className={cx('user-name')}>{item.nick_name}</span>
                                            <span className={cx('level')}>{Lever(item.level)}</span>

                                        </div>
                                        <div className={cx('after')}>
                                            <span className={cx('comment-time')}>{PostTime(item.time)}</span>
                                        </div>
                                    </div>
                                    <p className={cx('description')}>
                                        {item.content}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {
                            item.repComment?.map((result, index) => (
                                <div key={index} className={cx('wrap-rep-comment')}>
                                    <div className={cx('comment', 'rep-comment')}>
                                        <div className={cx('logo')}>
                                            <img alt='avatar' src={`https://i.ytimg.com/vi/${result.avatar}/mqdefault.jpg`} />
                                        </div>

                                        <div className={cx('wrap-content')}>
                                            <div className={cx('wrap-user-name')}>
                                                <div className={cx('infor-user-name')}>
                                                    <div className={cx('before')}>
                                                        <span className={cx('user-name')}>{result.nick_name}</span>
                                                        <span className={cx('level')}>{Lever(result.level)}</span>
                                                    </div>

                                                    <div className={cx('after')}>
                                                        <span className={cx('comment-time')}>{PostTime(result.time)}</span>
                                                    </div>
                                                </div>
                                                <p className={cx('description')}>
                                                    {result.content}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ))
            }

            <div className={cx('container-pagignate')}>
                <ReactPaginate
                    breakLabel={false}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={4}
                    pageCount={pageCount}
                    previousLabel="<"
                    nextLabel={'>'}
                    renderOnZeroPageCount={null}
                    breakClassName={cx('break')}
                    containerClassName={cx('pagignate')}
                    pageClassName={cx('list')}
                    previousClassName={cx('previous')}
                    nextClassName={cx('next')}
                    activeClassName={cx('active')}
                    disabledLinkClassName={cx('disabled')}
                />
            </div>
        </div>
    );
}

export default CommentPage;
