import { faWrench, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import styles from './UserInforPageItems.module.scss';
import PostTime from '../../../components/PostTime/PostTime';
import Experience from '../../../components/Experience/Experience';
import { useState } from 'react';
import axios from 'axios';
import { login } from '../../../reducer/userSlice';
import Lever from '../../../components/Lever/Lever';
import { backendUrl } from '../../../config';

let cx = classNames.bind(styles);

function Profile() {

    // infor film
    const inforUser = useSelector((state) => {
        return state.user.value
    })

    // use dispatch
    const dispatch = useDispatch()

    const [showUpdateInfor, setShowUpdateInfor] = useState(false)

    const [valueNickName, setValueNickName] = useState([])

    const handleUpdateInfor = () => {
        if (valueNickName.trim() === inforUser?.nick_name) {
            return alert('Bạn chưa sửa thông tin')
        }
        async function getUser() {
            try {
                const response = await axios.put(`${backendUrl}/users/change-infor`, {
                    valueNickName: valueNickName,
                    userId: inforUser?.id
                })

                if (response.data[0] === 'Update nickName sucessfully!') {
                    dispatch(login(response.data[1]))
                    setShowUpdateInfor(false)
                }

            } catch (error) {
                console.error(error);
            }
        }
        getUser()
    }

    return (
        <div className={cx('wrap-profile')}>
            {
                showUpdateInfor ?
                    <div className={cx('can-change')}>
                        <div className={cx('wrap-item')}>
                            <label>Nickname:</label>
                            <
                                input
                                value={valueNickName}
                                onChange={e => {
                                    setValueNickName(e.target.value)
                                }}

                            />
                        </div>
                    </div>
                    :
                    <div className={cx('none-change')}>
                        <div className={cx('wrap-item')}>
                            <span>Nickname:</span>
                            <div>{inforUser && inforUser.nick_name}</div>
                        </div>
                    </div>
            }

            <div className={cx('none-change')}>

                <div className={cx('wrap-item')}>
                    <span>Level:</span>
                    <div>{inforUser && Lever(inforUser?.level)}</div>
                </div>

                <div className={cx('wrap-item')}>
                    <span>Kinh nghiệm:</span>
                    <div>
                        {inforUser && Experience(inforUser.level)}
                    </div>
                </div>

                <div className={cx('wrap-item')}>
                    <span>Email:</span>
                    <div>{inforUser && inforUser.email}</div>
                </div>

                <div className={cx('wrap-item')}>
                    <span>Ngày tham gia:</span>
                    <div>{inforUser && PostTime(inforUser.registration_date)}</div>
                </div>
            </div>

            {
                showUpdateInfor ?

                    <div className={cx('wrap-save')}>
                        <button
                            className={cx('save')}
                            onClick={handleUpdateInfor}
                        >
                            <FontAwesomeIcon icon={faWrench} />
                            <span>
                                Lưu
                            </span>
                        </button>
                        <button
                            onClick={() => {
                                setShowUpdateInfor(false)
                            }}
                            className={cx('cancle')}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                            <span>Hủy</span>
                        </button>
                    </div>

                    :

                    <div className={cx('wrap-save')}>
                        <button
                            onClick={() => {
                                setShowUpdateInfor(true)
                                setValueNickName(inforUser?.nick_name)
                            }}>
                            <FontAwesomeIcon icon={faWrench} />
                            <span>Sửa thông tin</span>
                        </button>
                    </div>
            }
        </div>
    );
}

export default Profile;
