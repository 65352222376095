import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical, faPenFancy, } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import ReactPaginate from 'react-paginate';
import { memo } from 'react';
import axios from 'axios';

import styles from './Comment.module.scss'
import AddComment from './CommentItems/WrapComment/AddComment';
import UpdateComment from './CommentItems/WrapComment/UpdateComment';
import PostTime from '../../components/PostTime/PostTime'
import DeleteComment from './CommentItems/WrapComment/DeleteComment';
import AddRepComment from './CommentItems/WrapRepComment/AddRepComment';
import DeleteRepComment from './CommentItems/WrapRepComment/DeleteRepComment';
import UpdateRepComment from './CommentItems/WrapRepComment/UpdateRepComment';
import Lever from '../Lever/Lever';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

let cx = classNames.bind(styles);

function Comment({ itemsPerPage = 5, url, filmId, episode, userId = false }) {

    // selector
    const inforUser = useSelector((state) => {
        return state.user.value
    })

    const [itemOffset, setItemOffset] = useState(0);
    const [items, setItems] = useState([])

    // value editor rep comment
    const [valueUpdateRepComment, setValueUpdateRepComment] = useState('')

    // id rep comment where update rep comment
    const [commentRepId, setCommentRepId] = useState()

    const [showEditorComment, setShowEditorComment] = useState(false)

    // show block rep comment
    const [showBlockRepComment, setShowBlockRepComment] = useState(true)

    // show block rep rep comment
    const [showBlockRepRepComment, setShowBlockRepRepComment] = useState(true)

    // value update comment
    const [valueUpdateComment, setValueUpdateComment] = useState('')

    // dùng chung value rep comment và value rep rep comment
    const [valueRepComment, setValueRepComment] = useState('')

    // rerender
    const [rerender, setRerender] = useState(false)

    // commentId
    const [commentId, setCommentId] = useState()

    // content comment editor
    const [showBlockUpdateComment, setShowBlockUpdateComment] = useState(false)

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    useEffect(() => {
        async function getUser() {
            try {
                const response = await axios.get(`${url}`);

                setItems(response.data)
            } catch (error) {
                console.error(error);
            }
        }
        getUser()
    }, [rerender, url])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };


    return (
        <div className={cx('comment-section')}>
            <div className={cx('wrap-title')}>
                <div className={cx('title')}>
                    <span>{items?.length} bình luận</span>
                </div>
            </div>
            <div className={cx('log-in')}>
                {userId ?
                    (<AddComment
                        rerender={rerender}
                        setRerender={setRerender}
                        filmId={filmId}
                        episode={episode}
                        userId={userId}
                    />)
                    :
                    <Link to='/log-in' className={cx('btn-warning-login')}>Đăng nhập để bình luận</Link>
                }

                {/* content comment editor */}
                {showBlockUpdateComment &&
                    <UpdateComment
                        setShowBlockUpdateComment={setShowBlockUpdateComment}
                        commentId={commentId}
                        setValueUpdateComment={setValueUpdateComment}
                        valueUpdateComment={valueUpdateComment}
                        rerender={rerender}
                        setRerender={setRerender}
                    />
                }
            </div>

            <div className={cx('wrap-comment')}>
                {
                    currentItems?.map((item, index) => (
                        <div key={index} >
                            <div className={cx('comment')}>
                                <div className={cx('logo')}>
                                    {
                                        item.avatar === null || !item.avatar.includes('googleusercontent')
                                            ?
                                            <img alt='avatar' src={`/avatar-vo-danh-37.jpg`} />
                                            :
                                            <img alt='avatar' src={item.avatar} />
                                    }
                                </div>

                                <div className={cx('wrap-content')}>
                                    <div className={cx('wrap-user-name')}>
                                        <div className={cx('infor-user-name')}>
                                            <div className={cx('before')}>
                                                <span className={cx('user-name')}>{item.nick_name}</span>
                                                <span className={cx('level')}>{Lever(item.level)}</span>

                                            </div>

                                            <div className={cx('after')}>
                                                <span className={cx('comment-time')}>{PostTime(item.time)}</span>
                                                {/* Chỉ người dùng bình luận mới có thể chỉnh sửa */}
                                                {userId === item.user_id &&
                                                    (<div className={cx('editor-comment')}>
                                                        {
                                                            (showEditorComment === item.id
                                                                ?
                                                                <div
                                                                    className={cx('after')}
                                                                    onClick={() => {
                                                                        setShowEditorComment(false)
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className={cx('icon')}
                                                                        icon={faEllipsisVertical}
                                                                    />

                                                                    <div className={cx('editor')}>
                                                                        <div

                                                                            onClick={() => {
                                                                                setShowBlockUpdateComment(true)
                                                                                setCommentId(item.id)
                                                                                setValueUpdateComment(item.content)
                                                                            }}
                                                                        >

                                                                            <FontAwesomeIcon icon={faPenFancy}
                                                                            />
                                                                            <span >Sửa</span>
                                                                        </div>
                                                                        <DeleteComment
                                                                            item={item}
                                                                            rerender={rerender}
                                                                            setRerender={setRerender}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className={cx('after')} onClick={() => {
                                                                    setShowEditorComment(item.id)
                                                                }}>
                                                                    <FontAwesomeIcon className={cx('icon')} icon={faEllipsisVertical} />
                                                                </div>
                                                            )
                                                        }
                                                    </div>)
                                                }
                                            </div>
                                        </div>
                                        <p className={cx('description')}>
                                            {item.content}
                                        </p>
                                    </div>
                                    {
                                        showBlockRepComment === item.id
                                            ?
                                            <div className={cx('wrap-render-like')}>
                                                <span
                                                    className={cx('rep')}
                                                    onClick={() => {
                                                        if (!inforUser) {
                                                            alert('Hãy đăng nhập để bình luận!')
                                                        } else {
                                                            setShowBlockRepComment(false)
                                                        }
                                                    }}>
                                                    Trả lời
                                                </span>
                                            </div>
                                            :
                                            <div className={cx('wrap-render-like')}>
                                                <span
                                                    className={cx('rep')}
                                                    onClick={() => {
                                                        if (!inforUser) {
                                                            alert('Hãy đăng nhập để bình luận!')
                                                        } else {
                                                            setValueRepComment(`@${item.nick_name} `)
                                                            setShowBlockRepComment(item.id)
                                                            setShowBlockRepRepComment(false)
                                                        }
                                                    }}>
                                                    Trả lời
                                                </span>
                                            </div>
                                    }
                                </div>
                            </div>

                            {/* add rep comment */}
                            {item.id === showBlockRepComment &&
                                <div className={cx('wrap-render-form-update-comment')}>
                                    <AddRepComment
                                        userId={userId}
                                        setShowBlockRepRepComment={setShowBlockRepRepComment}
                                        setShowBlockRepComment={setShowBlockRepComment}
                                        item={item}
                                        rerender={rerender}
                                        setRerender={setRerender}
                                        valueRepComment={valueRepComment}
                                        setValueRepComment={setValueRepComment}
                                    />
                                </div>
                            }

                            {
                                item?.repComment.map((result, index) => (
                                    <div key={index} className={cx('wrap-rep-comment')}>
                                        <div className={cx('wrap-render-rep-comment')} key={index} >
                                            {result.comment_rep_id !== commentRepId ?
                                                <div className={cx('comment', 'rep-comment')}>
                                                    <div className={cx('logo')}>
                                                        {
                                                            result.avatar === null || !result.avatar.includes('googleusercontent')
                                                                ?
                                                                <img alt='avatar' src={`/avatar-vo-danh-37.jpg`} />
                                                                :
                                                                <img alt='avatar' src={result.avatar} />
                                                        }
                                                    </div>

                                                    <div className={cx('wrap-content')}>
                                                        <div className={cx('wrap-user-name')}>
                                                            <div className={cx('infor-user-name')}>
                                                                <div className={cx('before')}>
                                                                    <span className={cx('user-name')}>{result.nick_name}</span>
                                                                    <span className={cx('level')}>{Lever(result.level)}</span>
                                                                </div>

                                                                <div className={cx('after')}>
                                                                    <span className={cx('comment-time')}>{PostTime(result.time)}</span>
                                                                    {/* Chỉ người dùng bình luận mới có thể chỉnh sửa */}
                                                                    {userId === result.user_id
                                                                        &&
                                                                        <div className={cx('editor-comment')}>
                                                                            {(showEditorComment === result.comment_rep_id
                                                                                ?
                                                                                <div
                                                                                    className={cx('after')}
                                                                                    onClick={() => {
                                                                                        setShowEditorComment(false)
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        className={cx('icon')}
                                                                                        icon={faEllipsisVertical}
                                                                                    />
                                                                                    <div className={cx('editor')}>
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                setCommentRepId(result.comment_rep_id)
                                                                                                setValueUpdateRepComment(result.content)
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faPenFancy}
                                                                                            />
                                                                                            <span >Sửa</span>
                                                                                        </div>

                                                                                        {/* delete rep comment */}
                                                                                        <DeleteRepComment result={result} rerender={rerender} setRerender={setRerender} />

                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className={cx('after')} onClick={() => {
                                                                                    setShowEditorComment(result.comment_rep_id)
                                                                                }}>
                                                                                    <FontAwesomeIcon className={cx('icon')} icon={faEllipsisVertical} />
                                                                                </div>)}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <p className={cx('description')}>
                                                                {result.content}
                                                            </p>
                                                        </div>
                                                        {
                                                            showBlockRepRepComment === result.comment_rep_id
                                                                ?
                                                                <div className={cx('wrap-render-like')}>
                                                                    <span
                                                                        className={cx('rep')}
                                                                        onClick={() => {
                                                                            if (!inforUser) {
                                                                                alert('Hãy đăng nhập để bình luận!')
                                                                            } else {
                                                                                setShowBlockRepRepComment(false)
                                                                            }
                                                                        }}>
                                                                        Trả lời
                                                                    </span>
                                                                </div>
                                                                :
                                                                <div className={cx('wrap-render-like')}>
                                                                    <span
                                                                        className={cx('rep')}
                                                                        onClick={() => {
                                                                            if (!inforUser) {
                                                                                alert('Hãy đăng nhập để bình luận!')
                                                                            } else {
                                                                                setValueRepComment(`@${result.nick_name} `)
                                                                                setShowBlockRepRepComment(result.comment_rep_id)
                                                                                setShowBlockRepComment(false)
                                                                            }
                                                                        }}>
                                                                        Trả lời
                                                                    </span>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <UpdateRepComment
                                                    rerender={rerender}
                                                    setRerender={setRerender}
                                                    valueUpdateRepComment={valueUpdateRepComment}
                                                    setValueUpdateRepComment={setValueUpdateRepComment}
                                                    setCommentRepId={setCommentRepId}
                                                    result={result}
                                                />
                                            }
                                        </div>

                                        {/* add rep comment */}
                                        {result.comment_rep_id === showBlockRepRepComment &&
                                            <div className={cx('wrap-render-form-update-comment')}>
                                                <AddRepComment
                                                    userId={userId}
                                                    setShowBlockRepRepComment={setShowBlockRepRepComment}
                                                    setShowBlockRepComment={setShowBlockRepComment}
                                                    item={item}
                                                    rerender={rerender}
                                                    setRerender={setRerender}
                                                    valueRepComment={valueRepComment}
                                                    setValueRepComment={setValueRepComment}
                                                />
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }

                <div className={cx('container-pagignate')}>
                    <ReactPaginate
                        breakLabel={false}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={4}
                        pageCount={pageCount}
                        previousLabel="<"
                        nextLabel={'>'}
                        renderOnZeroPageCount={null}
                        breakClassName={cx('break')}
                        containerClassName={cx('pagignate')}
                        pageClassName={cx('list')}
                        previousClassName={cx('previous')}
                        nextClassName={cx('next')}
                        activeClassName={cx('active')}
                        disabledLinkClassName={cx('disabled')}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(Comment)