const Experience = (experience) => {
    let formattedNumber = experience.toLocaleString('en-US');

    switch (true) {
        case experience < 10:
            return `${formattedNumber}/10`
        case experience < 100:
            return `${formattedNumber}/100`
        case experience < 1000:
            return `${formattedNumber}/1,000`
        case experience < 10000:
            return `${formattedNumber}/10,000`
        case experience < 100000:
            return `${formattedNumber}/100,000`
        case experience < 1000000:
            return `${formattedNumber}/1,000,000`
        case experience < 10000000:
            return `${formattedNumber}/10,000,000`
        default:
            console.log('invalid');
    }
}

export default Experience