import classNames from 'classnames/bind';
import styles from './NotifyMovieInformationPage.module.scss';
import { toast } from 'react-toastify';
import FilmReview from '../MovieInformationPageItems/FilmReview';

let cx = classNames.bind(styles);

// film-review
const MsgFilmReview = () => (
    <div className={cx('film-review')}>
        <div className={cx('first')}>
            <div className={cx('words')}>Đánh giá phim</div>
        </div>
        <FilmReview displayMsg={displayMsg} />
    </div>
)

// create displayMsg
export const displayMsg = (action) => {

    switch (action) {
        case 'filmReview':
            toast(< MsgFilmReview />, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
                closeOnClick: false,
                className: cx('foo-bar'),
                closeButton: true
            });
            break;
        default:
            alert('dont pass action')
    }
}