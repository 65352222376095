import classNames from 'classnames/bind';
import { useState } from 'react';
import axios from 'axios';

import styles from './AddEpisodeFilm.module.scss'
import { backendUrl } from '../../config';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

let cx = classNames.bind(styles);

function AddEpisodeFilm() {

  const [searchParams] = useSearchParams();
  const filmId = searchParams.get('id');
  const [filmName, setFilmName] = useState(() => {
    return searchParams.get('filmName');
  })

  const [filmEpisode, setFilmEpisode] = useState('')
  const [filmSource, setFilmSource] = useState('')
  const [videoLink1, setVideoLink1] = useState('')
  const [videoLink2, setVideoLink2] = useState('')
  const [videoLink3, setVideoLink3] = useState('')

  const handleAddFilm = (e) => {
    e.preventDefault()
    if (filmEpisode.trim() === '' || filmSource.trim() === '' || videoLink1.trim() === '') {
      return alert('Chưa nhập đủ trường cần nhập')
    }

    const request = {
      filmId,
      filmEpisode,
      filmSource,
      videoLink1,
      videoLink2,
      videoLink3
    }

    async function getUser() {
      try {
        const response = await axios.post(`${backendUrl}/admin/add-episode-film`, request)

        if (response.data === 'Add episode film sucessfully!') {
          alert(`Thêm tập của phim ${filmName} thành công`)
        } else if (response.data === 'Tập phim đã tồn tại!') {
          alert('Tập phim đã tồn tại!y')
        }

      } catch (error) {
        console.error(error);
      }
    }
    getUser()

  }

  return (
    <div className={cx('wrap-add-film')}>
      <h2 className={cx('topic')}>Thêm phim</h2>
      <form onSubmit={handleAddFilm} className={cx('form')}>
        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='movie-name'>Tên phim</label>
          <input value={filmName} onChange={(e) => {
            setFilmName(e.target.value)
          }} className={cx('input-text')} id='movie-name' placeholder='...' />
        </div>
        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='movie-fiom'>Tập</label>
          <input value={filmEpisode} onChange={(e) => {
            setFilmEpisode(e.target.value)
          }} className={cx('input-text')} id='movie-fiom' placeholder='...' />
        </div>

        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='movie-source'>Nguồn phim</label>
          <input value={filmSource} onChange={(e) => {
            setFilmSource(e.target.value)
          }} className={cx('input-text')} id='movie-source' placeholder='...' />
        </div>

        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='video-link1'>Link 1</label>
          <input value={videoLink1} onChange={(e) => {
            setVideoLink1(e.target.value)
          }} className={cx('input-text')} id='video-link1' placeholder='...' />
        </div>

        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='video-link2'>Link 2</label>
          <input value={videoLink2} onChange={(e) => {
            setVideoLink2(e.target.value)
          }} className={cx('input-text')} id='video-link2' placeholder='...' />
        </div>

        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='video-link3'>Link 3</label>
          <input value={videoLink3} onChange={(e) => {
            setVideoLink3(e.target.value)
          }} className={cx('input-text')} id='video-link3' placeholder='...' />
        </div>

        <button className={cx('btn-submit')} type='submit'>Thêm phim</button>
      </form>
    </div>
  )
}

export default AddEpisodeFilm;
