import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import axios from 'axios';

import styles from './UserInforPageItems.module.scss';
import Topic from '../../../components/Topic/Topic';
import Paginate from '../../../components/Paginate/Paginate';
import { useSelector } from 'react-redux';
import { backendUrl } from '../../../config';

let cx = classNames.bind(styles);

function FollowedPage() {

    const [data, setData] = useState()

    // use selector
    const inforUsers = useSelector(state => (state.user.value))

    useEffect(() => {
        async function getUser() {
            try {
                const response = await axios.get(`${backendUrl}/users/followed-film`, {
                    params: {
                        userId: inforUsers.id ? inforUsers.id : 0
                    }
                });
                console.log('data', response.data);
                setData(response.data)
            } catch (error) {
                console.error(error);
            }
        }
        getUser()
    }, [])

    return (
        <div className={cx('followed')}>

            {/* stylesMovie */}
            <Topic content='Phim bạn theo dõi' />

            {/* WrapInforMovie */}
            {
                inforUsers && data && <Paginate itemsPerPage={30} topic={false} topMovie={false} items={data} />
            }
        </div>
    );
}

export default FollowedPage;
