import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay, faFileCirclePlus, faFileExcel, faHeartCircleCheck, faHeartCircleMinus, faStar } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState, createContext } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';

import styles from './MovieInformationPage.module.scss';
import Comment from '../../components/Comment/Comment'
import Topic from '../../components/Topic/Topic';
import { displayMsg } from './components/NotifyMovieInformationPage'
import { FilterGenres } from '../../components/FilterMovie/FilterMovie';
import { updateDataFilter, updateGenres, updateStatus, updateTopMovie, updateYears } from '../../reducer/filterSlice';
import { backendUrl } from '../../config';

let cx = classNames.bind(styles);

export const MyContext = createContext()

function MovieInformationPage() {

  // use selector
  const inforUsers = useSelector(state => (state.user.value))
  const { listDetailFilm } = useSelector((state) => state.filter)

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [data, setData] = useState()
  const [isFollowed, setIsFollowed] = useState(false)

  useEffect(() => {
    const getData = async function () {
      try {
        const query1 = axios.get(`${backendUrl}/films/infor-film/${params.paramName}`);

        const query2 = axios.get(`${backendUrl}/users/followed-only-film`, {
          params: {
            paramName: params.paramName,
            userId: inforUsers === null ? null : inforUsers?.id
          }
        });

        const [response1, response2] = await Promise.all([query1, query2])
        setData(response1.data)
        setIsFollowed(response2.data.length > 0)

      } catch (error) {
        console.error(error);
      }
    }
    getData()

  }, [params.paramName])

  // handle add follow
  const handleAddFollow = async () => {
    try {
      const response = await axios.post(`${backendUrl}/users/add-follow`, {
        filmId: data?.id,
        userId: inforUsers?.id === null ? 0 : inforUsers?.id
      })
      if (response.data === 'Sucess') {
        alert('Thêm theo dõi thành công!')
        setIsFollowed(true)
      }
    } catch (err) {
      console.log(err);
    }
  }

  // handle add follow
  const handleRemoteFollow = async () => {
    async function getUser() {
      try {
        const response = await axios.delete(`${backendUrl}/users/remote-follow`, {
          data: {
            filmId: data?.id,
            userId: inforUsers?.id === null ? 0 : inforUsers?.id

          }
        })

        if (response.data === 'Delete Sucess') {
          alert('Xóa theo dõi thành công!')
          setIsFollowed(false)
        }
      } catch (err) {
        console.log(err);
      }
    }
    getUser()
  }

  const handleFilterGenre = (genre) => {
    const dataFilter = FilterGenres(listDetailFilm, [genre]);
    dispatch(updateDataFilter(dataFilter));
    // để hủy tất cả các lựa chọn genre
    dispatch(updateGenres(''));
    // để chọn chỉ một genre
    dispatch(updateGenres(genre));
    dispatch(updateYears(''));
    dispatch(updateStatus(''));
    dispatch(updateTopMovie(''));
    navigate('/filter-movie');
  }

  const handleConvertWatchMovie = (slug, episode) => {
    if (!episode)
      return alert('Chưa cập nhật tập, vui lòng quay lại sau!')

    navigate(`/watch-movie/${slug}/${episode}`)
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }

  return (
    <div className={cx('movie-information')}>

      {/* infor-movie */}
      <div className={cx('wrap-infor-movie')}>
        <Topic content={data?.name} />

        <div className={cx('infor-movie')}>
          <div className={cx('wrap-img-and-wrap-infor-mobile')}>
            <div className={cx('wrap-img')}>
              <img alt='xin chao' src={data?.image} />
            </div>
          </div>

          <div className={cx('wrap-row')}>
            <div className={`hide-on-m ${cx('row')}`}>
              <div className={cx('title')}>{data?.name}</div>
            </div>

            <div className={cx('row')}>
              <div className={cx('column-1')}>Tình trạng</div>
              <div className={cx('column-2')}>
                <span>{data?.number_episodes === data?.current_episode ? 'Đã hoàn thành' : 'Đang tiến hành'}</span>
              </div>
            </div>

            <div className={cx('row')}>
              <div className={cx('column-1')}>Năm</div>
              <div className={cx('column-2')}>
                {
                  data &&
                  <span>{data?.movie_duration || '??'}
                  </span>
                }
              </div>
            </div>

            <div className={cx('row')}>
              <div className={cx('column-1')}>Đã cập nhật</div>
              <div className={cx('column-2')}>
                <span>
                  {data?.current_episode || '??'}
                  &nbsp;-	&nbsp;
                  {data?.number_episodes || '??'}
                </span>
              </div>
            </div>

            <div className={cx('row')}>
              <div className={cx('column-1')}>Xếp hạng</div>
              <div className={cx('column-2')}>
                <span>
                  {(data?.medium_point !== undefined) ? data?.medium_point.avg : 0}

                  &nbsp; -	&nbsp;
                  {data?.medium_point !== undefined ? data?.medium_point.count : 0}
                  Lượt đánh giá
                </span>
              </div>
            </div>

            <div className={cx('row')}>
              <div className={cx('column-1')}>Lượt xem</div>
              <div className={cx('column-2')}>
                <span>
                  {data?.views || '??'}
                </span>
              </div>
            </div>

            <div className={cx('row')}>
              <div className={cx('column-1')}>Tập gần nhất</div>
              <div className={cx('column-2')}>
                {data?.recent_episode?.map((episode, index) => (
                  <Link
                    to={`/watch-movie/${data?.slug}/${episode.episode}`}
                    className={cx('btn')}
                    key={index}
                  >
                    {episode.episode}
                  </Link>
                ))}
              </div>
            </div>

            <div className={cx('wrap-genre')}>
              {data?.genres?.map((genre, index) => (
                <Link
                  className={cx('link')}
                  onClick={() => { handleFilterGenre(genre.genre) }}
                  key={index}
                >
                  {genre.genre}
                </Link>
              ))}
            </div>

            <div className={cx('wrap-btn-status-film')}>
              {
                data
                &&
                <button
                  onClick={() => {
                    handleConvertWatchMovie(data?.slug, data?.minEpisode)
                  }}
                  className={cx('btn', 'btn-watch')}>
                  <FontAwesomeIcon icon={faCirclePlay} />
                  <span className={cx('content')}>Xem phim</span>
                </button>
              }

              {inforUsers ?
                (isFollowed
                  ?
                  <button
                    className={cx('btn', 'btn-follow')}
                    onClick={handleRemoteFollow}
                  >
                    <FontAwesomeIcon icon={faHeartCircleMinus} />
                    <span className={cx('content')}>Xóa theo dõi</span>
                  </button>
                  :
                  <button
                    className={cx('btn', 'btn-follow')}
                    onClick={handleAddFollow}
                  >
                    <FontAwesomeIcon icon={faHeartCircleCheck} />
                    <span className={cx('content')}>Theo dõi</span>
                  </button>
                )
                :
                (
                  <button
                    style={{ 'background': '#309b30' }}
                    className={cx('btn', 'btn-follow')}
                    onClick={() => {
                      alert('Bạn chưa đăng nhập!')
                    }}
                  >
                    <FontAwesomeIcon icon={faFileCirclePlus} />
                    <span className={cx('content')}>Theo dõi</span>
                  </button>
                )
              }

              <button
                className={cx('btn', 'btn-evaluate')}
                onClick={() => {
                  displayMsg('filmReview')
                }}
              >
                <FontAwesomeIcon icon={faStar} />
                <span className={cx('content')}>Đánh giá</span>
              </button>

            </div>

            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar
              newestOnTop={false}
              rtl={false}
              pauseOnFocusLoss
              // autoClose={true}
              draggable
              pauseOnHover
              theme="dark"
              closeButton={false}
            />
          </div>
        </div>
      </div>

      {/* part-of-movie */}
      {data?.related_film?.length !== 0 &&
        <div className={cx('part-of-movie')}>
          <h2 className={cx('title')}>Phim liên quan</h2>

          <div className={cx('wrap-movie-related')}>
            <div className={cx('btn-convert-movie', 'current-movie')}>
              <Link
                className={cx('btn-convert-movie-link')}
                to={`/movie-information/${data?.slug}`}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "auto",
                  });
                }}
              >
                {data?.name}
              </Link>
            </div>
            {data?.related_film?.map((item, index) => (
              <div key={index} className={cx('btn-convert-movie')}>
                <Link
                  className={cx('btn-convert-movie-link')}
                  to={`/movie-information/${item.related_slug}`}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "auto",
                    });
                  }}
                >
                  {item?.related_name}
                </Link>
              </div>
            ))}
          </div>
        </div>
      }
      <div className={cx('wrap-descript')}>
        <span className={cx('title')}>Mô tả: </span>
        {data && <div className={cx('descript-wrap-content')}>
          <span className={cx('descript')}>{data?.description}</span>
        </div>}
      </div>

      {/* comment-section */}
      {data && <Comment
        url={`${backendUrl}/films/comment-film?slug=${params.paramName}`}
        filmId={data?.id}
        userId={inforUsers?.id}
        episode={data?.minEpisode}
      />}
    </div>
  );
}

export default MovieInformationPage;
