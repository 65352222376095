// layout
import LayoutDefault from './layout/LayoutDefault/LayoutDefault';
import LayoutAdmin from './layout/LayoutAdmin/LayoutAdmin';
// page
import HomePage from './page/HomePage/HomePage'
import MovieInformationPage from './page/MovieInformationPage/MovieInformationPage'
import WatchMoviePage from './page/WatchMoviePage/WatchMoviePage'
import FilterMoviePage from './page/FilterMoviePage/FilterMoviePage'
import LogInPage from './page/LogInPage/LogInPage'
import ForgotPasswordPage from './page/ForgotPasswordPage/ForgotPasswordPage'
import RegisterPage from './page/RegisterPage/RegisterPage'
import UserInforPage from './page/UserInforPage/UserInforPage'
import TestPage from './page/TestPage/TestPage'
import SearchFilmPage from './page/SearchFilmPage/SearchFilmPage';
import NotificationPage from './page/NotificationPage/NotificationPage';
import NotFoundPage from './page/NotFoundPage/NotFoundPage';
// admin page
import AddEpisodeFilm from './page/AddEpisodeFilm/AddEpisodeFilm';
import AddFilmPage from './page/AddFilmPage/AddFilmPage';
import ListFilmPage from './page/ListFilmPage/ListFilmPage';
import InforEpisodeFilmPage from './page/InforEpisodeFilmPage/InforEpisodeFilmPage';
import DetailErrorPage from './page/DetailErrorPage/DetailErrorPage';
import AllErrorPage from './page/AllErrorPage/AllErrorPage';

const routes = [
    { path: '/', element: <LayoutDefault topMovie={false}><HomePage /></LayoutDefault> },
    { path: '/movie-information/:paramName', element: <LayoutDefault><MovieInformationPage /></LayoutDefault> },
    { path: '/watch-movie/:paramName/:paramEpisode', element: <LayoutDefault><WatchMoviePage /></LayoutDefault> },
    { path: '/filter-movie', element: <LayoutDefault topMovie={false}><FilterMoviePage /></LayoutDefault> },
    { path: '/log-in', element: <LayoutDefault topMovie={false}><LogInPage /></LayoutDefault> },
    { path: '/forgot-password', element: <LayoutDefault><ForgotPasswordPage /></LayoutDefault> },
    { path: '/register', element: <LayoutDefault topMovie={false}><RegisterPage /></LayoutDefault> },
    { path: '/user/infor', element: <LayoutDefault topMovie={false}><UserInforPage /></LayoutDefault> },
    { path: '/search-film', element: <LayoutDefault topMovie={false}><SearchFilmPage /></LayoutDefault> },
    { path: '/notification', element: <LayoutDefault><NotificationPage /></LayoutDefault> },
    { path: '/admin/add-film', element: <LayoutAdmin><AddFilmPage /></LayoutAdmin> },
    { path: '/admin/add-episode-film', element: <LayoutAdmin><AddEpisodeFilm /></LayoutAdmin> },
    { path: '/admin/list-film', element: <LayoutAdmin><ListFilmPage /></LayoutAdmin> },
    { path: '/admin/infor-episode-film', element: <LayoutAdmin><InforEpisodeFilmPage /></LayoutAdmin> },
    { path: '/admin/detail-error/:id', element: <LayoutAdmin><DetailErrorPage /></LayoutAdmin> },
    { path: '/admin/all-error', element: <LayoutAdmin><AllErrorPage /></LayoutAdmin> },
    { path: '/test', element: <TestPage /> },
    { path: '*', element: <NotFoundPage /> },
]

export default routes