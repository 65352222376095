
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import axios from 'axios';
import { useSelector } from 'react-redux';


import styles from './MovieInformationPageItems.module.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { backendUrl } from '../../../config';

let cx = classNames.bind(styles);

const FilmReview = ({ displayMsg, setIsRenderEvFilm }) => {
    const [listEvaluate, setListEvaluate] = useState()

    // use selector
    const inforUsers = useSelector(state => (state.user.value))

    const params = useParams()

    useEffect(() => {
        const getData = async function () {
            try {
                const response = await axios.get(`${backendUrl}/users/list-evaluate`);
                setListEvaluate(response.data)

            } catch (error) {
                console.error(error);
            }
        }
        getData()
    }, [])

    const handleClick = (id) => {
        if (!inforUsers)
            alert('Bạn chưa đăng nhập!')
        else {
            const getData = async function () {
                try {
                    const response = await axios.post(`${backendUrl}/users/add-evaluate`, {
                        userId: inforUsers.id,
                        params: params.paramName,
                        evaluateId: id
                    });

                    if (response.data === 'Add evaluate Sucess') {
                        alert('Thêm đánh giá thành công!');
                        toast.dismiss()
                    }
                    else if (response.data === 'Update evaluate Sucess') {
                        alert('Cập nhật đánh giá thành công!');
                        toast.dismiss()
                    }
                } catch (error) {
                    console.error(error);
                }
            }
            getData()
        }
    }

    return (
        <div className={cx('second')}>

            {listEvaluate && listEvaluate.map((item, index) => (
                <div key={index} className={cx('icon')} onClick={() => {
                    handleClick(item.id)
                }} star={item.id}>
                    <FontAwesomeIcon icon={faStar} />
                </div>
            ))}
        </div>
    )
}

export default FilmReview