let currentDate = new Date();

// Lọc thể loại
const FilterGenres = (listFilter, selectFilter) => {
    const resultFilter = listFilter.filter(film => {
        return selectFilter.every(select => film.genre_film.includes(select));
      });
    return resultFilter
}
 
// Lọc năm
const FilterYears = (listFilter, selectFilter) => {
    const resultFilter = listFilter.filter(film => {
        return selectFilter.includes(film.year)
    })
    return resultFilter
}

// Lọc trạng thái
const FilterStatus = (listFilter, selectFilter) => {
    if (selectFilter === 'Đã hoàn thành') {
        return listFilter.filter(film => +film.number_episodes === +film.current_episode);
    } else if (selectFilter === 'Chưa hoàn thành') {
        return listFilter.filter(film => +film.number_episodes !== +film.current_episode);
    }
}

// Lọc top movie
const FilterTopMovie = (listFilter, listTopMonth, listTopWeek, listTopDay, selectFilter) => {
    switch (selectFilter) {
        case 'Ngày cập nhật':
            return [...listFilter].sort((a, b) => a.year - b.year);
        break;
        case 'Top evaluate':
            return [...listFilter].sort((a, b) => b.medium_point - a.medium_point);
        break;
        case 'Top following':
            return [...listFilter].sort((a, b) => b.followed_film - a.followed_film);
        break;
        case 'Top all':
            return [...listFilter].sort((a, b) => b.views - a.views);
        break;
        case 'Top month':{
            return [...listTopMonth].sort((a, b) => b.views - a.views);
        break;
        }
        case 'Top week':{
            return [...listTopWeek].sort((a, b) => b.views - a.views);
        break;
        }
        case 'Top day':{
            return [...listTopDay].sort((a, b) => b.views - a.views);
        break;
        }
        default:
            console.log('sai')
    }
}
 

export {
    FilterGenres, FilterYears, FilterStatus, FilterTopMovie
}