

const NotFoundPage = () => {

  return (

    <div>
      Not found 404
    </div>
  );
};

export default NotFoundPage;
