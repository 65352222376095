import classNames from 'classnames/bind';
import { Link } from "react-router-dom";

import styles from './InforMovie.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHeart, faStar } from '@fortawesome/free-solid-svg-icons';
import PostTime from '../PostTime/PostTime';

let cx = classNames.bind(styles);

function InforMovie({ data, keys, onClick }) {

    let {
        id,
        name = '?????',
        slug,
        image,
        time_view,
        current_episode = '??',
        number_episodes = '??',
        medium_point,
        followed_film,
        views
    } = data

    let props = {
        onClick
    }

    return (
        <div {...props} className={cx('infor-movie')}>
            <Link to={`/movie-information/${slug}`} className={cx('container-img')}>
                <img alt={`Ảnh anime ${name}`} src={`${image}`} />
                {number_episodes === 'Full' ?
                    (<span className={cx('episode')}>Full</span>)
                    :
                    (<span className={cx('episode')}>{current_episode || '??'}/{number_episodes || '??'}</span>)
                }
                {
                    time_view
                    &&
                    <span className={cx('day-time')}>{PostTime(time_view)}</span>
                }

                {
                    medium_point && <div className={cx('medium-point')}>
                        <FontAwesomeIcon icon={faStar} />
                        <span>{medium_point}</span>
                    </div>
                }

                <div className={cx('wrap-bottom')}>
                    <h3 className={cx('name')}>{name}</h3>

                    <div className={cx('wrap-status')}>
                        {
                            views && <div className={cx('btn-status')}>
                                <FontAwesomeIcon icon={faEye} />
                                <span>{views}</span>
                            </div>
                        }

                        {
                            followed_film ?
                                <div className={cx('btn-status')}>
                                    <FontAwesomeIcon icon={faHeart} />
                                    <span>{followed_film}</span>
                                </div>
                                :
                                undefined
                        }
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default InforMovie;
