import classNames from 'classnames/bind';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft, faCircleChevronRight, faHeart, faHeartCircleCheck, faHeartCircleMinus, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import styles from './WatchMoviePage.module.scss';
import Comment from '../../components/Comment/Comment'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { backendUrl } from '../../config';

let cx = classNames.bind(styles);

function WatchMoviePage() {
  const [data, setData] = useState('')

  const params = useParams()

  // server 
  const [server, setServer] = useState('server1')
  const [isFollowed, setIsFollowed] = useState(false)

  // pre & next episode
  const [preEpisode, setPreEpisode] = useState()
  const [nextEpisode, setNextEpisode] = useState()

  // error
  const [errorServer, setErrorServer] = useState('')
  const [errorDescript, setErrorDescript] = useState('')

  const [showFormError, setShowFormError] = useState(false)

  // use selector
  const inforUsers = useSelector(state => (state.user.value))

  useEffect(() => {
    let a;
    const getData = async function () {
      try {
        const query1 = await axios.get(`${backendUrl}/films/watch-film/${params.paramName}/${params.paramEpisode}`);
        const query2 = axios.get(`${backendUrl}/users/followed-only-film`, {
          params: {
            paramName: params.paramName,
            userId: inforUsers === null ? null : inforUsers?.id
          }
        });

        const [response1, response2] = await Promise.all([query1, query2])

        setIsFollowed(response2.data.length > 0)

        setData(response1.data)
        a = response1.data

        const indexEpisode = response1.data?.list_film?.findIndex((episode) => (
          episode.episode === params.paramEpisode
        ))

        setPreEpisode(response1.data?.list_film[indexEpisode - 1]?.episode)
        setNextEpisode(response1.data?.list_film[indexEpisode + 1]?.episode)
      } catch (error) {
        console.error(error);
      }
    }
    getData()

    // add history
    const timeAddHistory = setTimeout(() => {
      if (inforUsers) {
        const getData = async function () {
          try {
            await axios.post(`${backendUrl}/users/add-history`, {
              userId: inforUsers?.id,
              filmId: a?.infor_film.id,
              episode: params.paramEpisode
            })
          } catch (error) {
            console.error(error);
          }
        }
        getData()
      }
    }, 5000)

    // add views of film
    const timeAddViews = setTimeout(() => {
      if (inforUsers) {
        const getData = async function () {
          try {
            await axios.post(`${backendUrl}/films/add-views-film`, {
              userId: inforUsers?.id,
              slug: params.paramName
            })
          } catch (error) {
            console.error(error);
          }
        }
        getData()
      }
    }, 5000)

    return () => {
      clearTimeout(timeAddHistory)
      clearTimeout(timeAddViews)
    }
  }, [params])

  const handleAddFollowed = async () => {
    try {
      const response = await axios.post(`${backendUrl}/users/add-follow`, {
        filmId: data?.infor_film?.id,
        userId: inforUsers?.id === null ? 0 : inforUsers?.id
      })
      if (response.data === 'Sucess') {
        alert('Thêm theo dõi thành công!')
        setIsFollowed(true)
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleRemoteFollowed = async () => {
      try {
        const response = await axios.delete(`${backendUrl}/users/remote-follow`, {
          data: {
            filmId: data?.infor_film?.id,
            userId: inforUsers?.id === null ? 0 : inforUsers?.id

          }
        })

        if (response.data === 'Delete Sucess') {
          alert('Xóa theo dõi thành công!')
          setIsFollowed(false)
        }
      } catch (err) {
        console.log(err);
      }
  }

  const handleSubmitError = () => {
    if (errorDescript.trim() === '' && errorServer === '') {
      return alert('Vui lòng chọn server bị lỗi hoặc mô tả nội dung lỗi')
    }

    const addError = async function () {
      try {
        const response = await axios.post(`${backendUrl}/admin/add-error`, {
          errorServerValue: errorServer,
          errorDescriptValue: errorDescript,
          filmId: data?.infor_film.id,
          filmEpisode: data?.infor_film.episode
        })

        if (response.data === 'Add error sucessfully!') {
          alert('Lỗi sẽ được khắc phục sớm nhất. Cảm ơn bạn đã đưa ra ý kiến!')
          setShowFormError(false)
          setErrorDescript('')
        }
      } catch (error) {
        console.error(error);
      }
    }
    addError()
  }

  return (
    <div className={cx('watch-movie')}>

      {/* movie-details */}
      <div className={cx('movie-details')}>
        <div className={cx('wrap-name')}>
          <Link className={cx('name')}>
            {data?.infor_film?.name}
            - Tập {data?.infor_film?.episode !== 10000 ? (data?.infor_film?.episode) : 'Full'}
          </Link>
        </div>
      </div>

      {/* video */}
      <div className={cx('video')}>
        {data && <iframe width="640" allowFullScreen={true} frameBorder='0' height="360" src={data.infor_film[server]} title={data.infor_film?.name} />}
      </div>

      {/* button-server */}
      <div className={cx('movie-wrap-button')}>
        <div className={cx('button-server')}>
          {
            data?.infor_film?.server1
            &&
            <button
              className={cx('server')}
              style={server === 'server1' ? { 'background': '#d44d4d' } : { 'background': '#1e1d1d' }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                })

                setServer('server1')
              }}
            >
              Server 1
            </button>
          }

          {
            data?.infor_film?.server2
            &&
            <button
              className={cx('server')}
              style={server === 'server2' ? { 'background': '#d44d4d' } : { 'background': '#1e1d1d' }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                })

                setServer('server2')
              }}
            >
              Server 2
            </button>
          }

          {
            data?.infor_film?.server3
            &&
            <button
              className={cx('server')}
              style={server === 'server3' ? { 'background': '#d44d4d' } : { 'background': '#1e1d1d' }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                })

                setServer('server3')
              }}
            >
              Server 3
            </button>
          }
        </div>
      </div>

      {/* option */}
      <div className={cx('option')}>

        <div className={cx('wrap-utilities')}>
          <div
            tabIndex={0}
            onClick={() => {
              setShowFormError(!showFormError)
            }}
            className={cx('utilities')}
          >
            <span>Báo lỗi</span>
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </div>

          {
            showFormError
            &&
            <div className={cx('form-error')} >
              <div className={cx('wrap-server')}>
                <label>Server</label>
                <select onChange={(e) => {
                  setErrorServer(e.target.value)
                }}>
                  <option value=''>Chọn Server</option>
                  <option value='server 1'>Server1</option>
                  <option value='server 2'>Server2</option>
                  <option value='defferent'>Khác</option>
                  <option value='cả 2'>Cả 2</option>
                </select>
              </div>

              <div className={cx('wrap-descript')}>
                <h3>Mô tả</h3>
                <textarea
                  spellCheck={false}
                  onChange={(e) => {
                    setErrorDescript(e.target.value)
                  }} value={errorDescript} placeholder='Lỗi...' />
              </div>

              <div className={cx('wrap-btn-submit')}>
                <button onClick={() => {
                  setShowFormError(false)
                }} className={cx('btn-submit')}>Hủy</button>

                <button onClick={handleSubmitError} className={cx('btn-submit', 'send')}>Gửi</button>

              </div>
            </div>
          }

          <div></div>

        </div>

        <div className={cx('wrap-utilities')}>
          {
            !isFollowed
              ?
              <div
                tabIndex={0}
                onClick={handleAddFollowed}
                className={cx('utilities')}
              >
                <span>Theo dõi</span>
                <FontAwesomeIcon icon={faHeartCircleCheck} />
              </div>
              :
              <div
                style={{ 'background': '#d44d4d' }}
                tabIndex={0}
                onClick={handleRemoteFollowed}
                className={cx('utilities')}
              >
                <span>Bỏ theo dõi</span>
                <FontAwesomeIcon icon={faHeartCircleMinus} />
              </div>
          }
        </div>

        <div className={cx('wrap-utilities')}>

          {
            preEpisode ?
              <Link
                to={`/watch-movie/${params.paramName}/${preEpisode}`}
                tabIndex={0}
                className={cx('utilities')}
              >
                <span>Trước</span>
                <FontAwesomeIcon icon={faCircleChevronLeft} />
              </Link>
              :
              <div
                tabIndex={0}
                style={{ 'opacity': '.5', 'pointerEvents': 'none' }}
                className={cx('utilities')}
              >
                <span>Trước</span>
                <FontAwesomeIcon icon={faCircleChevronLeft} />
              </div>
          }
        </div>

        <div className={cx('wrap-utilities')}>
          {
            nextEpisode ?
              <Link
                to={`/watch-movie/${params.paramName}/${nextEpisode}`}
                tabIndex={0}
                className={cx('utilities')}
              >
                <span>Sau</span>
                <FontAwesomeIcon icon={faCircleChevronRight} />
              </Link>
              :
              <div
                tabIndex={0}
                style={{ 'opacity': '.5', 'pointerEvents': 'none' }}
                className={cx('utilities')}
              >
                <span>Sau</span>
                <FontAwesomeIcon icon={faCircleChevronRight} />
              </div>
          }
        </div>
      </div>

      {/* list-episode */}
      <div className={cx('list-episode')}>
        <div className={cx('before')}>Danh sách tập</div>
        <div className={cx('after')}>
          <div className={cx('wrap-list-episode')}>
            {data && data.list_film?.map((item, index) => {
              return (< Link key={index}
                className={cx('wrap-list-episode-link')}
                style={item.episode === params.paramEpisode ? { 'backgroundColor': '#bb6464' } : {}}
                to={`/watch-movie/${data?.infor_film?.slug}/${item.episode}`}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
                  setServer('server1')
                }}
              >
                {item.episode}
              </Link>)
            })}
          </div>
        </div>
      </div>

      {/* Comment */}
      {
        data && (<Comment
          url={`${backendUrl}/films/comment-episode-film/${params.paramName}/${params.paramEpisode}`}
          filmId={data?.infor_film?.id}
          episode={params.paramEpisode}
          userId={inforUsers?.id}
        />)
      }
    </div >
  );
}

export default WatchMoviePage;
