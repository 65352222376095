import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import styles from './Navbar.module.scss'
import { updateYears, updateStatus, updateDataFilter, updateGenres, updateTopMovie } from '../../../reducer/filterSlice';
import { FilterGenres, FilterStatus, FilterTopMovie, FilterYears } from '../../../components/FilterMovie/FilterMovie';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { backendUrl } from '../../../config';
import { setRenderPageUser } from '../../../reducer/renderPageUserSlice';

let cx = classNames.bind(styles);

function Navbar() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { listDetailFilm, listTopMonth, listTopWeek, listTopDay } = useSelector((state) => state.filter)

    const [genresList, setGenresList] = useState()
    const [yearList, setYearList] = useState()


    // render filter
    useEffect(() => {
        async function getUser() {
            try {
                const query1 = axios.get(`${backendUrl}/films/list-genre`)
                const query2 = axios.get(`${backendUrl}/films/list-year`)

                const [response1, response2] = await Promise.all([query1, query2])

                setGenresList(response1?.data)
                setYearList(response2?.data)
            } catch (error) {
                console.error(error);
            }
        }
        getUser()
    }, [])

    const handleFilterStatus = (status) => {
        const dataFilter = FilterStatus(listDetailFilm, status);
        dispatch(updateDataFilter(dataFilter));
        dispatch(updateGenres(''));
        dispatch(updateYears(''));
        dispatch(updateStatus(status));
        dispatch(updateTopMovie(''));
        navigate('/filter-movie');
    }

    const handleFilterTopMovie = (topMovie) => {
        const dataFilter = FilterTopMovie(listDetailFilm, listTopMonth, listTopWeek, listTopDay, topMovie);
        dispatch(updateDataFilter(dataFilter));
        dispatch(updateGenres(''));
        dispatch(updateYears(''));
        dispatch(updateStatus(''));
        dispatch(updateTopMovie(topMovie));
        navigate('/filter-movie');
    }

    const handleFilterYear = (year) => {
        const dataFilter = FilterYears(listDetailFilm, [year]);
        dispatch(updateDataFilter(dataFilter));
        dispatch(updateGenres(''));
        // để hủy tất cả các lựa chọn year
        dispatch(updateYears(''));
        // để chọn chỉ một year
        dispatch(updateYears(year));
        dispatch(updateStatus(''));
        dispatch(updateTopMovie(''));
        navigate('/filter-movie');
    }

    const handleFilterGenre = (genre) => {
        const dataFilter = FilterGenres(listDetailFilm, [genre]);
        dispatch(updateDataFilter(dataFilter));
        // để hủy tất cả các lựa chọn genre
        dispatch(updateGenres(''));
        // để chọn chỉ một genre
        dispatch(updateGenres(genre));
        dispatch(updateYears(''));
        dispatch(updateStatus(''));
        dispatch(updateTopMovie(''));
        navigate('/filter-movie');
    }

    return (
        <div className={` hide-on-m ${cx('navbar')}`}>
            <div className='grid'>
                <div className='wide'>
                    <ul className={cx('navbar-list')}>
                        <li className={cx('navbar-item')}>
                            <Link to='/' className={cx('wrap-topic')}>
                                <span className={cx('topic')}>
                                    Trang chủ
                                </span>
                            </Link>
                        </li>

                        <li className={cx('navbar-item')}>
                            <Link
                            to='/user/infor'
                            className={cx('wrap-topic')}
                            onClick={() => {
                                dispatch(setRenderPageUser('showFollowedPage'))
                            }}
                            >
                                <span className={cx('topic')}>
                                    Theo dõi
                                </span>
                            </Link>
                        </li>

                        <li className={cx('navbar-item')}>
                            <div
                                className={cx('wrap-topic')}
                            >
                                <span className={cx('topic')}>Trạng thái</span>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </div>
                            <ul className={cx('menu-list')}>
                                <li
                                    onClick={() => { handleFilterStatus('Đã hoàn thành') }}
                                    className={cx('menu-item')}
                                >
                                    <div className={cx('menu-item-link')}>Đã hoàn thành</div>
                                </li>
                                <li
                                    onClick={() => { handleFilterStatus('Chưa hoàn thành') }}
                                    className={cx('menu-item')}
                                >
                                    <div className={cx('menu-item-link')}>Chưa hoàn thành</div>
                                </li>
                            </ul>
                        </li>

                        <li className={cx('navbar-item')}>

                            <div
                                className={cx('wrap-topic')}
                            >
                                <span className={cx('topic')}>Thể loại</span>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </div>
                            <ul className={`row ${cx('menu-list-flex', 'genre-list')}`}>
                                {
                                    genresList?.map(genre => (
                                        <li
                                            key={genre.genre}
                                            onClick={() => { handleFilterGenre(genre.genre) }}
                                            className={`l-3 ${cx('menu-item')}`}
                                        >
                                            <Link className={cx('menu-item-link')}>{genre.genre}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </li>

                        <li className={cx('navbar-item')}>
                            <div
                                className={cx('wrap-topic')}
                            >
                                <span className={cx('topic')}>Top phim</span>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </div>

                            <ul className={`row ${cx('menu-list-flex')}`}>
                                <li
                                    onClick={() => { handleFilterTopMovie('Top month') }}
                                    className={`l-6 ${cx('menu-item')}`}
                                >
                                    <Link className={cx('menu-item-link')}>Top tháng</Link>
                                </li>
                                <li
                                    onClick={() => { handleFilterTopMovie('Top week') }}
                                    className={`l-6 ${cx('menu-item')}`}
                                >
                                    <Link className={cx('menu-item-link')}>Top tuần</Link>
                                </li>
                                <li
                                    onClick={() => { handleFilterTopMovie('Top day') }}
                                    className={`l-6 ${cx('menu-item')}`}
                                >
                                    <Link className={cx('menu-item-link')}>Top ngày</Link>
                                </li>
                                <li
                                    onClick={() => { handleFilterTopMovie('Top evaluate') }}
                                    className={`l-6 ${cx('menu-item')}`}
                                >
                                    <Link className={cx('menu-item-link')}>Đánh giá</Link>
                                </li>
                                <li
                                    onClick={() => { handleFilterTopMovie('Top following') }}
                                    className={`l-6 ${cx('menu-item')}`}
                                >
                                    <Link className={cx('menu-item-link')}>Theo dõi</Link>
                                </li>
                            </ul>
                        </li>

                        <li className={cx('navbar-item')}>
                            <div
                                className={cx('wrap-topic')}
                            >
                                <span className={cx('topic')}>Năm</span>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </div>
                            <ul className={`row ${cx('menu-list-flex')}`}>
                                {
                                    yearList?.map(year => (
                                        <li
                                            key={year.year}
                                            onClick={() => { handleFilterYear(year.year) }}
                                            className={`l-6 ${cx('menu-item')}`}
                                        >
                                            <Link className={cx('menu-item-link')}>{year.year || '??'}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Navbar