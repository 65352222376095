
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './MenuUser.module.scss'
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { faArrowRightArrowLeft, faArrowRightFromBracket, faChevronDown, faSquarePlus, faUserLarge } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { logout } from '../../reducer/userSlice';
import axios from 'axios';
import { setRenderPageUser } from '../../reducer/renderPageUserSlice';
import { backendUrl } from '../../config';


let cx = classNames.bind(styles);

function MenuUser() {

    const [showNotification, setShowNotification] = useState(false)
    const [notification, setNotification] = useState()
    const [showMenuUser, setShowMenuUser] = useState(false)

    const [rerender, setRerender] = useState(true)

    // use dispatch
    const dispatch = useDispatch()

    // use navigate
    const naviagte = useNavigate()

    // selector
    const inforUser = useSelector((state) => {
        return state.user.value
    })

    // useEffect(() => {
    //     async function getUser() {
    //         try {
    //             const response = await axios.get(`${backendUrl}/users/list-notification`, {
    //                 params: {
    //                     userId: inforUser.id
    //                 }
    //             });

    //             setNotification(response.data)
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }
    //     getUser()
    // }, [rerender])

    const handleShowNotification = (e) => {
        setShowNotification(!showNotification)
    }

    // handle log out
    const handleLogOut = async (e) => {
        e.preventDefault()

        dispatch(logout())
        naviagte('/')
    }

    const handleUpdateIsRead = (id) => {
        async function getUser() {
            try {
                const response = await axios.put(`${backendUrl}/users/update-isread`, {
                    id: id
                })

                if (response.data === 'Update isread succesfully!')
                    setRerender(!rerender)
            } catch (error) {
                console.error(error);
            }
        }
        getUser()

        setShowNotification(!showNotification)
    }

    return (
        <div className={cx('wrap-menu-user')}>
            {/* <div className={cx('wrap-notifycation')}>
                <div className={cx('show-notify')} onClick={() => {
                    setShowNotification(!showNotification)
                }}>
                    <FontAwesomeIcon icon={faBell} />
                </div>
                {
                    showNotification
                    &&
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className={cx('menu-notify')}
                    >
                        <div className={cx('notifycation-header')}>
                            <h2 className={cx('notifycation-title')}>Thông báo</h2>
                            <Link className={cx('notifycation-all')} to={`/notification?id=full&userId=${inforUser.id}`}>Xem tất cả</Link>
                        </div>

                        <ul className={cx('notifycation-list')}>
                            {notification?.map(item => (
                                <li key={item.id} className={item.is_read ? cx('notifycation-item', 'isread') : cx('notifycation-item')}>
                                    <Link onClick={() => {
                                        handleUpdateIsRead(item.id)
                                    }} to={`/notification?id=${item.id}`} className={cx('notifycation-item-link')}>
                                        <div className={cx('wrap-image')}>
                                            <img alt='img' src={item.image} />
                                        </div>

                                        <div className={cx('wrap-infor')}>
                                            <h3>{item.title}</h3>
                                            <p>{item.content}</p>
                                            <span>{PostTime(item.time)}</span>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                }

            </div> */}

            <div className={cx('wrap-menu-user')}>
                <div className={cx('show-menu-user')} onClick={() => {
                    setShowMenuUser(!showMenuUser)
                }}>
                    {
                        inforUser.avatar === null || !inforUser.avatar.includes('googleusercontent')
                            ?
                            <img className={cx('avatar')} alt='avata' src='/avatar-vo-danh-37.jpg' />
                            :
                            <img className={cx('avatar')} alt='avata' src={inforUser.avatar} />

                    }
                    <FontAwesomeIcon className={cx('fa-down')} icon={faChevronDown} />
                </div>

                {
                    showMenuUser
                    &&
                    <div className={cx('menu-user')}>
                        <Link className={cx('menu-link')} to='/user/infor' onClick={() => {
                            dispatch(setRenderPageUser('profile'))
                            setShowMenuUser(false)
                        }}>
                            <span className={cx('menu-title', 'name')}>{inforUser?.nick_name}</span>
                        </Link>

                        <Link className={cx('menu-link')} to='/user/infor' onClick={() => {
                            dispatch(setRenderPageUser('showFollowedPage'))
                            setShowMenuUser(false)
                        }}>
                            <FontAwesomeIcon className={cx('menu-icon')} icon={faSquarePlus} />
                            <span className={cx('menu-title')}>Theo dõi</span>
                        </Link>

                        <Link className={cx('menu-link')} to='/user/infor' onClick={() => {
                            dispatch(setRenderPageUser('updatePassword'))
                            setShowMenuUser(false)
                        }}>
                            <FontAwesomeIcon className={cx('menu-icon')} icon={faArrowRightArrowLeft} />
                            <span className={cx('menu-title')}>Thay đổi mật khẩu</span>
                        </Link>

                        <Link className={cx('menu-link')} href='/' onClick={handleLogOut}>
                            <FontAwesomeIcon className={cx('menu-icon')} icon={faArrowRightFromBracket} />
                            <span className={cx('menu-title')}>Đăng xuất</span>
                        </Link>
                    </div>
                }
            </div>
        </div>
    )
}

export default MenuUser