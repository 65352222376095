import classNames from "classnames/bind"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import { memo } from 'react'
import axios from "axios"

import styles from '../CommentItems.module.scss'
import config from "../../../../config";

const cx = classNames.bind(styles)

const DeleteComment = ({
    item, rerender, setRerender
}) => {

    // handle remote comment
    const handleRemoteComment = async (commentId) => {
        const defind = window.confirm('Bạn chắc chắn muốn xóa!')
        if (!defind) {
            return;
        }

        async function getUser() {
            try {
                const response = await axios.delete(`${backendUrl}/users/remote-comment`, {
                    data: {
                        commentId: commentId
                    }
                })

                if (response.data === 'Delete comment succesfully!') {
                    setRerender(!rerender)
                }
            } catch (err) {
                console.log(err);
            }
        }
        getUser()
    }

    return (
        <div
            onClick={() => {
                handleRemoteComment(item.id)
            }}
        >
            <FontAwesomeIcon icon={faDeleteLeft} />
            <span>Xóa</span>
        </div>
    )
}

export default memo(DeleteComment)