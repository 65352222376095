import classNames from 'classnames/bind';
import { memo } from 'react';

import styles from './HomePage.module.scss';
import ReactSlick from './HomePageItems/ReactSlick'
import Topic from '../../components/Topic/Topic'
import Paginate from '../../components/Paginate/Paginate';
import { useSelector } from 'react-redux';

let cx = classNames.bind(styles);

function HomePage() {
  const { listDetailFilm } = useSelector((state) => state.filter)

  return (
    <div className={`${cx('container')}`}>

      {/* nominated-movie */}
      <div className={`${cx('wrap-topic')}`}>
        < Topic mw0={true} mh0={true} content='Phim đề cử' />
      </div>

      {/* react-slick */}
      < ReactSlick />

      {/* paginate */}
      {listDetailFilm && <Paginate itemsPerPage={30} items={listDetailFilm} />}
    </div >
  );
}

export default memo(HomePage);
