import classNames from 'classnames/bind';
import { useState } from 'react';
import axios from 'axios';

import styles from './AddFilmPage.module.scss'
import { backendUrl } from '../../config';

let cx = classNames.bind(styles);

const AddFilmPage = () => {

  const [filmName, setFilmName] = useState('')
  const [filmSlug, setFilmSlug] = useState('')
  const [filmDescription, setFilmDescription] = useState('')
  const [filmDuration, setFilmDuration] = useState('')
  const [numberEpisodes, setNumberEpisodes] = useState('')
  const [releaseYear, setReleaseYear] = useState('')
  const [filmThumbnail, setFilmThumbnail] = useState('')
  const [filmPart, setFilmPart] = useState('')
  const [filmGenres, setFilmGenres] = useState('')

  const handleAddFilm = (e) => {
    e.preventDefault()

    const request = {
      filmName,
      filmSlug,
      filmDescription,
      filmThumbnail,
      filmDuration,
      numberEpisodes,
      filmPart,
      releaseYear,
      filmGenres
    }

    async function getUser() {
      try {
        const response = await axios.post(`${backendUrl}/admin/add-film`, request)

        if (response.data === 'Add film sucessfully!') {
          alert('Thêm phim thành công')
        } else if (response.data === 'Name movie or Slug already exists!') {
          alert('Phim đã tồn tại')
        }

      } catch (error) {
        console.error(error);
      }
    }
    getUser()

  }

  return (
    <div className={cx('wrap-add-film')}>
      <h2 className={cx('topic')}>Thêm phim</h2>
      
      <form onSubmit={handleAddFilm} className={cx('form')}>
        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='movie-name'>Tên phim</label>
          <input
            value={filmName}
            onChange={(e) => {
              setFilmName(e.target.value)
            }}
            className={cx('input-text')} id='movie-name' placeholder='...'
          />
        </div>

        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='movie-slug'>Slug</label>
          <input
            value={filmSlug}
            onChange={(e) => {
              setFilmSlug(e.target.value)
            }}
            className={cx('input-text')}
            id='movie-slug'
            placeholder='...'
          />
        </div>

        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='movie-description'>Mô tả</label>
          <textarea
            value={filmDescription}
            onChange={(e) => {
              setFilmDescription(e.target.value)
            }}
            className={cx('area-text')}
            id='movie-description'
            placeholder='...'
          />
        </div>

        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='movie-theme'>Thumbnail</label>
          <input
            value={filmThumbnail}
            onChange={(e) => {
              setFilmThumbnail(e.target.value)
            }}
            className={cx('input-text')}
            id='movie-theme'
            placeholder='...'
          />
        </div>

        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='movie-duration'>Thời lượng phim</label>
          <input
            value={filmDuration}
            onChange={(e) => {
              setFilmDuration(e.target.value)
            }}
            className={cx('input-text')}
            id='movie-duration'
            placeholder='...'
          />
        </div>

        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='movie-year'>Năm phát hành</label>
          <input
            value={releaseYear}
            onChange={(e) => {
              setReleaseYear(e.target.value)
            }}
            className={cx('input-text')} id='movie-year' placeholder='...'
          />
        </div>

        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='series-movie-length'>Số lượng tập</label>
          <input
            value={numberEpisodes}
            onChange={(e) => {
              setNumberEpisodes(e.target.value)
            }}
            className={cx('input-text')}
            id='series-movie-length'
            placeholder='...'
          />
        </div>

        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='movie-part'>Phần</label>
          <input
            value={filmPart}
            onChange={(e) => {
              setFilmPart(e.target.value)
            }}
            className={cx('input-text')}
            id='movie-part'
            placeholder='...'
          />
        </div>
        
        <div className={cx('wrap-infor')}>
          <label className={cx('label-name')} htmlFor='movie-genres'>Thể loại</label>
          <input
            value={filmGenres}
            onChange={(e) => {
              setFilmGenres(e.target.value)
            }}
            className={cx('input-text')}
            id='movie-genres'
            placeholder='...'
          />
        </div>

        <button className={cx('btn-submit')} type='submit'>Thêm phim</button>
      </form>
    </div>
  )
}

export default AddFilmPage;
