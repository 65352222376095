import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './FilterMoviePage.module.scss';
import Topic from '../../components/Topic/Topic';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import Paginate from '../../components/Paginate/Paginate';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux"
import { updateYears, updateStatus, updateDataFilter, updateGenres, updateTopMovie } from '../../reducer/filterSlice';
import { FilterGenres, FilterStatus, FilterTopMovie, FilterYears } from '../../components/FilterMovie/FilterMovie';
import { backendUrl } from '../../config';


let cx = classNames.bind(styles);


function FilterMoviePage() {
  const [showFormFilter, setShowFormFilter] = useState(false)

  const [genresList, setGenresList] = useState()
  const [yearList, setYearList] = useState()

  const {
    listDetailFilm, listTopMonth, listTopWeek, listTopDay, dataFilter, selectGenres, seLectYears, selectStatus, selectTopMovie
  } = useSelector((state) => state.filter)

  const dispatch = useDispatch()

  // render filter
  useEffect(() => {
    async function getUser() {
      try {
        const query1 = axios.get(`${backendUrl}/films/list-genre`)
        const query2 = axios.get(`${backendUrl}/films/list-year`)

        const [response1, response2] = await Promise.all([query1, query2])

        setGenresList(response1?.data)
        setYearList(response2?.data)
      } catch (error) {
        console.error(error);
      }
    }
    getUser()
  }, [])

  const handleFilterMovie = (
    selectGenres,
    seLectYears,
    selectStatus,
    selectTopMovie
  ) => {
    const result = {
      genres: selectGenres,
      years: seLectYears,
      status: selectStatus,
      topMovie: selectTopMovie
    }

    // non select
    const nonSelectGenres = result.genres.length === 0
    const nonSelectYears = result.years.length === 0
    const nonSelectStatus = result.status === ''
    const nonSelectTopMovie = result.topMovie === ''

    // has select 
    const hasSelectGenres = result.genres.length !== 0
    const hasSelectYears = result.years.length !== 0
    const hasSelectStatus = result.status !== ''
    const hasSelectTopMovie = result.topMovie !== ''

    let resultFilter;
    let currentDate = new Date();
    // filter
    switch (true) {
      // filter one
      // filter genres
      case nonSelectYears && nonSelectStatus && nonSelectTopMovie && hasSelectGenres:
        resultFilter = FilterGenres(listDetailFilm, selectGenres)
        dispatch(updateDataFilter(resultFilter))
        break;

      // filter years
      case nonSelectGenres && nonSelectStatus && nonSelectTopMovie && hasSelectYears:
        resultFilter = FilterYears(listDetailFilm, seLectYears)
        dispatch(updateDataFilter(resultFilter))
        break;

      // filter status
      case nonSelectGenres && nonSelectYears && nonSelectTopMovie && hasSelectStatus:
        resultFilter = FilterStatus(listDetailFilm, selectStatus)
        dispatch(updateDataFilter(resultFilter));
        break;

      // filter top-movie
      case nonSelectGenres && nonSelectYears && nonSelectStatus && hasSelectTopMovie: {
        resultFilter = FilterTopMovie(listDetailFilm, listTopMonth, listTopWeek, listTopDay, result.topMovie)

        dispatch(updateDataFilter(resultFilter));
        break;
      }

      // filter two
      // filter genres and years
      case nonSelectStatus && nonSelectTopMovie && hasSelectGenres && hasSelectYears: {
        const filterGenres = FilterGenres(listDetailFilm, selectGenres)
        const resultFilter = FilterYears(filterGenres, seLectYears)

        dispatch(updateDataFilter(resultFilter))
        break;
      }
      // filter genres and status
      case nonSelectYears && nonSelectTopMovie && hasSelectGenres && hasSelectStatus: {
        const filterGenres = FilterGenres(listDetailFilm, selectGenres)
        const resultFilter = FilterStatus(filterGenres, selectStatus)

        dispatch(updateDataFilter(resultFilter))
        break;
      }
      // filter genres and top-movie
      case nonSelectYears && nonSelectStatus && hasSelectGenres && hasSelectTopMovie: {
        resultFilter = FilterTopMovie(listDetailFilm, listTopMonth, listTopWeek, listTopDay, result.topMovie)

        resultFilter = FilterGenres(resultFilter, selectGenres)
        dispatch(updateDataFilter(resultFilter));
        break;
      }

      // filter years and status
      case nonSelectGenres && nonSelectTopMovie && hasSelectYears && hasSelectStatus: {
        const filterYears = FilterYears(listDetailFilm, seLectYears)
        const resultFilter = FilterStatus(filterYears, selectStatus)

        dispatch(updateDataFilter(resultFilter))
        break;
      }

      // filter years and top-movie
      case nonSelectGenres && nonSelectStatus && hasSelectYears && hasSelectTopMovie: {
        resultFilter = FilterTopMovie(listDetailFilm, listTopMonth, listTopWeek, listTopDay, result.topMovie)

        resultFilter = FilterYears(resultFilter, seLectYears)
        dispatch(updateDataFilter(resultFilter));
        break;
      }

      // filter status and top-movie
      case nonSelectGenres && nonSelectYears && hasSelectStatus && hasSelectTopMovie: {
        resultFilter = FilterTopMovie(listDetailFilm, listTopMonth, listTopWeek, listTopDay, result.topMovie)

        resultFilter = FilterStatus(resultFilter, selectStatus)
        dispatch(updateDataFilter(resultFilter));
        break;
      }

      // filter three
      // filter genres and years and status
      case nonSelectTopMovie && hasSelectGenres && hasSelectYears && hasSelectStatus: {
        const filterGenres = FilterGenres(listDetailFilm, selectGenres)
        const filterYears = FilterYears(filterGenres, seLectYears)
        resultFilter = FilterStatus(filterYears, selectStatus)

        dispatch(updateDataFilter(resultFilter))
        break;
      }

      // filter genres and years and top-movie
      case nonSelectStatus && hasSelectGenres && hasSelectYears && hasSelectTopMovie: {
        resultFilter = FilterTopMovie(listDetailFilm, listTopMonth, listTopWeek, listTopDay, result.topMovie)

        const filterGenres = FilterGenres(resultFilter, selectGenres)
        resultFilter = FilterYears(filterGenres, seLectYears)
        dispatch(updateDataFilter(resultFilter));
        break;
      }

      // filter genres and status and top-movie
      case nonSelectYears && hasSelectGenres && hasSelectStatus && hasSelectTopMovie: {
        resultFilter = FilterTopMovie(listDetailFilm, listTopMonth, listTopWeek, listTopDay, result.topMovie)

        const filterGenres = FilterGenres(resultFilter, selectGenres)
        resultFilter = FilterStatus(filterGenres, selectStatus)

        dispatch(updateDataFilter(resultFilter));
        break;
      }

      // filter years and status and top-movie
      case nonSelectGenres && hasSelectYears && hasSelectStatus && hasSelectTopMovie: {
        resultFilter = FilterTopMovie(listDetailFilm, listTopMonth, listTopWeek, listTopDay, result.topMovie)

        const filterYears = FilterYears(resultFilter, seLectYears)
        resultFilter = FilterStatus(filterYears, selectStatus)
        dispatch(updateDataFilter(resultFilter));
        break;
      }

      // filter four
      // filter genres and years and status and top-movie
      case hasSelectGenres && hasSelectYears && hasSelectStatus && hasSelectTopMovie: {
        resultFilter = FilterTopMovie(listDetailFilm, listTopMonth, listTopWeek, listTopDay, result.topMovie)

        const filterGenres = FilterGenres(resultFilter, selectGenres)
        const filterYears = FilterYears(filterGenres, seLectYears)
        resultFilter = FilterStatus(filterYears, selectStatus)
        dispatch(updateDataFilter(resultFilter));
        break;
      }

      default:
        dispatch(updateDataFilter(listDetailFilm))
        console.log('end');
    }
  }

  //==>start Xử lý xóa từ khóa
  const handleCloseSelectGenres = (selectGenres, genre) => {
    dispatch(updateGenres(genre))

    const filterSelectGenres = selectGenres.filter(item => item !== genre)
    handleFilterMovie(filterSelectGenres, seLectYears, selectStatus, selectTopMovie);
    setShowFormFilter(true)
  }

  const handleCloseSelectYears = (seLectYears, year) => {
    dispatch(updateYears(year))

    const filterSelectYears = seLectYears.filter(item => item !== year)
    handleFilterMovie(selectGenres, filterSelectYears, selectStatus, selectTopMovie);
    setShowFormFilter(true)
  }

  const handleCloseSelectStatus = () => {
    dispatch(updateStatus(''))
    handleFilterMovie(selectGenres, seLectYears, '', selectTopMovie);
    setShowFormFilter(true)
  }
  
  const handleCloseSelectTopMovie = () => {
    dispatch(updateTopMovie(''))
    handleFilterMovie(selectGenres, seLectYears, selectStatus, '');
    setShowFormFilter(true)
  }
  // Xử lý xóa từ khóa  end<==

  return (
    <div className={cx('filter-movie')}>

      {/* Topic */}
      <div className={cx('filter-wrap-topic')}>
        <Topic content='Trang lọc phim' />
        <FontAwesomeIcon
          onClick={() => {
            setShowFormFilter(!showFormFilter)
          }}
          className={cx('btn-show-topic')}
          icon={faFilter}
        />
      </div>

      {showFormFilter && <div className={cx('form-filter')}>
        <div className={cx('form-wrap-filter')}>
          <h3 className={cx('form-topic')}>Thể loại</h3>
          <div className={cx('form-list')}>
            <ul className='row'>
              {genresList?.map((genre) => (
                <li className='l-3' key={genre.genre}>
                  <label htmlFor={genre.genre}>{genre.genre}</label>
                  <input
                    checked={selectGenres.includes(genre.genre)}
                    onChange={() => {
                      dispatch(updateGenres(genre.genre))
                    }}
                    id={genre.genre}
                    type='checkbox'
                    value={genre.genre}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={cx('form-wrap-filter')}>
          <h3 className={cx('form-topic')}>Năm</h3>
          <div className={cx('form-list')}>
            <ul className='row'>
              {yearList?.map((year) => (
                <li className='l-3' key={year.year}>
                  <label htmlFor={year.year}>{year.year}</label>
                  <input
                    checked={seLectYears.includes(year.year)}
                    onChange={() => {
                      dispatch(updateYears(year.year))
                    }}
                    id={year.year}
                    type='checkbox'
                    value={year.year}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={cx('form-wrap-filter')}>
          <h3 className={cx('form-topic')}>Trạng thái</h3>
          <div className={cx('form-list')}>
            <select
              value={selectStatus}
              onChange={(e) => {
                dispatch(updateStatus(e.target.value))
              }}
              name='status'
            >
              <option value=''>--Chọn--</option>
              <option value='Đã hoàn thành'>Đã hoàn thành</option>
              <option value='Chưa hoàn thành'>Chưa hoàn thành</option>
            </select>
          </div>
        </div>

        <div className={cx('form-wrap-filter')}>
          <h3 className={cx('form-topic')}>Top phim</h3>
          <div className={cx('form-list')}>
            <select
              value={selectTopMovie}
              onChange={(e) => {
                dispatch(updateTopMovie(e.target.value))
              }}
              name='topMovie'
            >
              <option value=''>--Chọn--</option>
              <option value='Ngày cập nhật'>Ngày cập nhật</option>
              <option value='Top all'>Top all</option>
              <option value='Top month'>Top tháng</option>
              <option value='Top week'>Top tuần</option>
              <option value='Top day'>Top ngày</option>
              <option value='Top evaluate'>Đánh giá</option>
              <option value='Top following'>Theo dõi</option>
            </select>
          </div>
        </div>

        <div className={cx('wrap-btn-filter')}>
          <button onClick={() => { setShowFormFilter(false) }} className={cx('btn-filter')}>Hủy</button>
          <button
            onClick={() => {
              handleFilterMovie(selectGenres, seLectYears, selectStatus, selectTopMovie)
            }}
            className={cx('btn-filter')}
          >Lọc
          </button>
        </div>
      </div>}

      <Topic content='Từ khóa' mh0={false} />
      {/* Xóa từ khóa */}
      <div className={cx('list-filter')}>
        {selectGenres.map(genre => (
          <div key={genre} className={cx('item-filter')}>
            <span className={cx('content')}>{genre}</span>
            <span
              className={cx('close')}
              onClick={() => {handleCloseSelectGenres(selectGenres, genre)}}
            >
              x
            </span>
          </div>
        ))}

        {seLectYears.map(year => (
          <div key={year} className={cx('item-filter')}>
            <span className={cx('content')}>{year}</span>
            <span
              className={cx('close')}
              onClick={() => {handleCloseSelectYears(seLectYears, year)}}
            >
              x
            </span>
          </div>
        ))}

        {
          selectStatus
          &&
          <div className={cx('item-filter')}>
            <span className={cx('content')}>{selectStatus}</span>
            <span
              className={cx('close')}
              onClick={() => {handleCloseSelectStatus()}}
            >
              x
            </span>
          </div>
        }

        {
          selectTopMovie
          &&
          <div className={cx('item-filter')}>
            <span className={cx('content')}>{selectTopMovie}</span>
            <span
              className={cx('close')}
              onClick={() => {handleCloseSelectTopMovie()}}
            >
              x
            </span>
          </div>
        }

      </div>
      {/* Paginate */}

      {dataFilter && <Paginate items={dataFilter} topMovie={false} />}

    </div >
  );
}

export default FilterMoviePage;
