import { createSlice } from '@reduxjs/toolkit'

export const renderPageUserSlice = createSlice({
    name: 'renderPageUser',
    initialState: {
        value: 'profile'
    },
    reducers: {
        setRenderPageUser: (state, action) => {
            state.value = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const { setRenderPageUser } = renderPageUserSlice.actions

export default renderPageUserSlice.reducer