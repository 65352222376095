import styles from './Random.module.scss'
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

let cx = classNames.bind(styles);

function Random() {
    const { listDetailFilm } = useSelector((state) => state.filter);

    // Hàm để chọn một phần tử ngẫu nhiên từ mảng
    const getRandomItem = (listDetailFilm) => {
        const randomIndex = Math.floor(Math.random() * listDetailFilm.length);
        return listDetailFilm?.[randomIndex].slug;
    }

    return (
        <div className={cx('wrap-random-movie')}>
            {
                listDetailFilm
                &&
                <Link
                    to={`/movie-information/${getRandomItem(listDetailFilm)}`}
                    className={cx('random-movie')}
                >
                    Phim ngẫu nhiên
                </Link>
            }
        </div>
    )
}

export default Random