import { createSlice } from '@reduxjs/toolkit'

export const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        listDetailFilm: [],
        listTopMonth: [],
        listTopWeek: [],
        listTopDay: [],
        dataFilter: [],
        selectGenres: [],
        seLectYears: [],
        selectStatus: '',
        selectTopMovie: '',
    },
    reducers: {
        updateListDetailFilm: (state, action) => {
            state.listDetailFilm = action.payload
        },
        updateListTopMonth: (state, action) => {
            state.listTopMonth = action.payload
        },
        updateListTopWeek: (state, action) => {
            state.listTopWeek = action.payload
        },
        updateListTopDay: (state, action) => {
            state.listTopDay = action.payload
        },
        updateDataFilter: (state, action) => {
            state.dataFilter = action.payload
        },
        updateGenres: (state, action) => {
            if (action.payload === '') {
                state.selectGenres = []
            } else if (state.selectGenres.includes(action.payload)) {
                state.selectGenres = state.selectGenres.filter(item => (
                    item !== action.payload
                ))
            } else {
                state.selectGenres = [
                    ...state.selectGenres,
                    action.payload
                ]
            }
        },
        updateYears: (state, action) => {
            if (action.payload === '') {
                state.seLectYears = []
            } else if (state.seLectYears.includes(action.payload)) {
                state.seLectYears = state.seLectYears.filter(item => (
                    item !== action.payload
                ))
            } else {
                state.seLectYears = [
                    ...state.seLectYears,
                    action.payload
                ]
            }
        },
        updateStatus: (state, action) => {
            state.selectStatus = action.payload
        },
        updateTopMovie: (state, action) => {
            state.selectTopMovie = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { 
    updateListDetailFilm, updateListTopMonth, updateListTopWeek, updateListTopDay, updateDataFilter, updateGenres, updateYears, updateStatus, updateTopMovie
} = filterSlice.actions

export default filterSlice.reducer