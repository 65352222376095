import classNames from 'classnames/bind';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import styles from './ListFilmPage.module.scss'
import { backendUrl } from '../../config';

let cx = classNames.bind(styles);

function ListFilmPage() {
  const [data, setData] = useState()

  const [searchValue, setSearchValue] = useState('')

  const [showUpdatefilm, setShowUpdateFilm] = useState('')

  const [reRender, setReRender] = useState(true)

  const [imageValue, setImageValue] = useState('')
  const [nameValue, setNameValue] = useState('')
  const [slugValue, setSlugValue] = useState('')
  const [genresValue, setGenresValue] = useState('')
  const [descriptionValue, setDescriptionValue] = useState('')
  const [yearValue, setYearValue] = useState('')
  const [durationValue, setDurationValue] = useState('')
  const [numberEpisodeValue, setNumberEpisodeValue] = useState('')
  const [partValue, setPartValue] = useState('')
  const [timeValue, setTimeValue] = useState('')

  useEffect(() => {

    async function getUser() {
      try {
        const response = await axios.get(`${backendUrl}/admin/search-film`, {
          params: {
            searchValue: ''
          }
        });
        setData(response.data)

      } catch (error) {
        console.error(error);
      }
    }
    getUser()
  }, [reRender])

  useEffect(() => {

    if (!searchValue) {
      return;
    }

    async function getUser() {
      try {
        const response = await axios.get(`${backendUrl}/admin/search-film`, {
          params: {
            searchValue: searchValue
          }
        });
        setData(response.data)

      } catch (error) {
        console.error(error);
      }
    }

    const searchTimeout = setTimeout(getUser, 1000)

    return () => {
      clearTimeout(searchTimeout)
    }
  }, [searchValue])

  const handleDeleteFilms = (id) => {
    async function deleteFilm() {
      try {
        const response = await axios.delete(`${backendUrl}/admin/remote-films`, {
          data: {
            filmId: id
          }
        });

        if (response.data === 'Remote film sucessfully!') {
          setReRender(!reRender)
        }

      } catch (error) {
        console.error(error);
      }
    }
    deleteFilm()
  }

  const handleUpdateFilm = (id) => {
    const data = {
      id: id,
      imageValue: imageValue,
      nameValue: nameValue,
      slugValue: slugValue,
      genresValue: genresValue,
      descriptionValue: descriptionValue,
      durationValue: durationValue,
      numberEpisodeValue: numberEpisodeValue,
      partValue, partValue,
      yearValue: yearValue,
      timeValue: timeValue
    }

    async function updateFilm() {
      try {
        const response = await axios.put(`${backendUrl}/admin/update-films`, data);

        if (response.data === 'Update films sucessfully!') {
          setShowUpdateFilm('')
          setReRender(!reRender)
        }
      } catch (error) {
        console.error(error);
      }
    }
    updateFilm()
  }
  
  return (
    <div className={cx('list-film')}>
      <div className={cx('wrap-heading')}>
        <h2 className={cx('topic')}>
          danh sach phim
        </h2>
        <div className={cx('wrap-search')}>
          <input value={searchValue} onChange={(e) => {
            setSearchValue(e.target.value)
          }} spellCheck={false} placeholder='Search...' />
        </div>
      </div>


      <div className={cx('wrap-list-film')}>
        {
          data?.map(item => (
            <div key={item.id} className={cx('film-item')}>
              <div className={cx('film-item-before')}>
                <div className={cx('wrap-id')}>
                  id
                  <span>{item.id}</span>
                </div>

                <div className={cx('wrap-image')}>
                  image
                  {
                    showUpdatefilm === item.id ?
                      <input value={imageValue} onChange={(e) => {
                        setImageValue(e.target.value)
                      }} />
                      :
                      <img alt={item.name} src={item.image} />
                  }
                </div>

                <div className={cx('wrap-name')}>
                  name
                  {
                    showUpdatefilm === item.id ?
                      <input value={nameValue} onChange={(e) => {
                        setNameValue(e.target.value)
                      }} />
                      :
                      <h3>{item.name}</h3>
                  }
                </div>

                <div className={cx('wrap-slug')}>
                  slug
                  {
                    showUpdatefilm === item.id ?
                      <input value={slugValue} onChange={(e) => {
                        setSlugValue(e.target.value)
                      }} />
                      :
                      <h3>{item.slug}</h3>
                  }
                </div>

                <div className={cx('wrap-genres')}>
                  genres
                  {
                    showUpdatefilm === item.id ?
                      <input value={genresValue} onChange={(e) => {
                        setGenresValue(e.target.value)
                      }} />
                      :
                      <ul>
                        {item.genre_film?.map(genre => (
                          <li key={genre}>{genre}</li>
                        ))}
                      </ul>
                  }
                </div>

                <div className={cx('wrap-description')}>
                  description
                  {
                    showUpdatefilm === item.id ?
                      <textarea value={descriptionValue} onChange={(e) => {
                        setDescriptionValue(e.target.value)
                      }} />
                      :
                      <p>{item.description}</p>
                  }
                </div>

                <div className={cx('wrap-views')}>
                  Lượt xem
                  <span>{item.views}</span>
                </div>

                <div className={cx('wrap-followingsValue')}>
                  Theo dõi
                      <span>{item.followed_film}</span>
                </div>

                <div className={cx('wrap-evaluates')}>
                  Đánh giá
                      <span>{item.medium_point}</span>
                </div>

                <div className={cx('wrap-evaluates')}>
                  Thời lượng tập
                  {
                    showUpdatefilm === item.id ?
                      <input value={durationValue} onChange={(e) => {
                        setDurationValue(e.target.value)
                      }} />
                      :
                      <span>{item.movie_duration}</span>
                  }
                </div>

                <div className={cx('wrap-evaluates')}>
                  Tập hiện tại
                      <span>{item.current_episode}</span>
                </div>

                <div className={cx('wrap-evaluates')}>
                  Tổng số tập
                  {
                    showUpdatefilm === item.id ?
                      <input value={numberEpisodeValue} onChange={(e) => {
                        setNumberEpisodeValue(e.target.value)
                      }} />
                      :
                      <span>{item.number_episodes}</span>
                  }
                </div>

                <div className={cx('wrap-evaluates')}>
                  Phần
                  {
                    showUpdatefilm === item.id ?
                      <input value={partValue} onChange={(e) => {
                        setPartValue(e.target.value)
                      }} />
                      :
                      <span>{item.movie_duration}</span>
                  }
                </div>

                <div className={cx('wrap-evaluates')}>
                  Năm phát hành
                  {
                    showUpdatefilm === item.id ?
                      <input value={yearValue} onChange={(e) => {
                        setYearValue(e.target.value)
                      }} />
                      :
                      <span>{item.year}</span>
                  }
                </div>

                <div className={cx('wrap-time')}>
                  time
                  {
                    showUpdatefilm === item.id ?
                      <input value={timeValue} onChange={(e) => {
                        setTimeValue(e.target.value)
                      }} />
                      :
                      <span>{item.time}</span>
                  }
                </div>

                <button onClick={() => {
                  handleDeleteFilms(item.id)
                }} className={cx('delete-film')}>Xóa</button>

              </div>

              {showUpdatefilm === item.id ?
                <div className={cx('film-item-after')}>
                  <button
                    onClick={() => {
                      handleUpdateFilm(item.id)
                    }}
                  >
                    Sửa
                  </button>

                  <button
                    onClick={() => {
                      setShowUpdateFilm('')
                    }}
                  >
                    Huỷ
                  </button>
                </div>

                :
                <div className={cx('film-item-after')}>
                  <Link className={cx('convert-episode-film')} to={`/admin/infor-episode-film?filmId=${item.id}&&filmName=${item.name}`}>Danh sách tập</Link>

                  <button
                    onClick={() => {
                      setShowUpdateFilm(item.id)
                      setImageValue(item.image)
                      setNameValue(item.name)
                      setSlugValue(item.slug)
                      setGenresValue(item.genre_film.join(','))
                      setDescriptionValue(item.description)
                      setDurationValue(item.movie_duration)
                      setNumberEpisodeValue(item.number_episodes)
                      setPartValue(item.part)
                      setYearValue(item.year)
                      setTimeValue(item.time)
                    }}
                  >
                    Sửa
                  </button>
                </div>
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default ListFilmPage;
