import classNames from 'classnames/bind';
import { useState, useEffect } from 'react';
import axios from 'axios';

import styles from './SearchFilmPage.module.scss';
import Topic from '../../components/Topic/Topic'
import Paginate from '../../components/Paginate/Paginate';
import { useLocation, useSearchParams } from 'react-router-dom';
import { backendUrl } from '../../config';

let cx = classNames.bind(styles);

function SearchFilmPage() {

  // searchValue
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const searchValue = searchParams.get('search')

  const [data, setData] = useState()

  useEffect(() => {
    if (searchValue.trim() !== '') {
      async function getUser() {
        try {
          const response = await axios.get(`${backendUrl}/films/search-film`, {
            params: {
              search: searchValue
            }
          });

          setData(response.data)
        } catch (error) {
          console.error(error);
        }
      }
      getUser()
    }

  }, [searchValue])

  return (
    <div className={cx('search-film-page')}>
      {/* first */}
      <Topic content={`Tìm kiếm với từ khóa: ${searchValue}`} />

      {/* second */}
      {data && <Paginate topic={false} items={data} />}
    </div>
  )
}

export default SearchFilmPage;
